import { useState, useEffect, useRef } from 'react';
// @mui
import { useMutation } from '@tanstack/react-query';
import {
  Container,
  Stack,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  Drawer,
  Pagination,
  Grid,
  List,
  ListItem,
  Divider,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
// config
// _mock
import { _courses } from 'src/_mock';
// components
//
import { useNavigate, useParams } from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';

import {
  WpVodControllerReadAdminAssessParams,
  WpVodControllerReadAdminsParams,
  WpVodControllerReadWpVodProductsParams,
  // WpVodControllerTeacherReivewParams,
} from 'src/generated';
import Image from 'src/components/image';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import ArticleIcon from '@mui/icons-material/Article';
import StarIcon from '@mui/icons-material/Star';
import Carousel, { CarouselArrows, CarouselDots } from 'src/components/carousel';
import { useTheme } from '@mui/material/styles';
import TestimonialItemTeacher from 'src/sections/testimonial/e-commerce/TestimonialItemTeacher';
import { publicApi } from '../../../react-query';
import SelectProductModal from '../teacher/item/SelectProductModal';

// ----------------------------------------------------------------------
const fetcherTeachers = (params: WpVodControllerReadAdminsParams) =>
  publicApi.vod.wpVodControllerReadAdmins(params);
const fetcherCourses = (params: WpVodControllerReadWpVodProductsParams) =>
  publicApi.vod.wpVodControllerReadWpVodProducts(params);
const fetcherReview = (params: WpVodControllerReadAdminAssessParams) =>
  publicApi.vod.wpVodControllerReadAdminAssess(params);
const defaultValues = {
  filterDuration: [],
  filterCategories: [],
  filterRating: 'all',
  filterFee: [],
  filterLevel: ['all'],
  filterLanguage: [],
};

export default function ElearningTeacherView() {
  /* eslint-disable */
  const [TeacherIdx, setTeacherIdx] = useState<any>();
  const [TeacherData, setTeacherData] = useState<any[]>([]);
  const [Open, setOpen] = useState<any>(false);
  const [CourseList, setCourseList] = useState<any>([]);
  const navigate = useNavigate();
  const [TeacherAvg, setTeacherAvg] = useState<any>('');
  const [TeacherCount, setTeacherCount] = useState<any>('');
  const [ReviewList, setReviewList] = useState<any>('');
  const isMdUp = useResponsive('up', 'md');
  const carouselRef = useRef<Carousel | null>(null);

  const { data: LengthList, mutate: mutateCourses } = useMutation(
    (params: WpVodControllerReadWpVodProductsParams) => fetcherCourses(params),
    {
      onSuccess: (e: any) => {
        // console.log(e)
        if (e.data.header.isSuccess) {
          console.log(e);
          // setPageSize(e.data.body.total)
          let list: any = [];
          e.data.body.data.filter((c:any)=>c.isLiveAdminFixed === 0).map((v: any) => {
            list.push({
              id: v.vodProductIdx,
              createdAt: new Date(),
              slug: v.vodProductTitle,
              coverImg: v.vodProductPhotoFilePath,
              category: v.vodProductCategoryInformation?.vodProductCategoryTitle,
              description: v.vodProductInfo,
              price: v.vodProductPrice,
              usetimer: v.isVodProductTimer,
              time: v.vodProductTimer,
              level:
                v.vodProductLevel === 1
                  ? 'Beginner'
                  : v.vodProductLevel === 2
                  ? 'Intermediate'
                  : 'Expert',
              priceSale: v?.vodProductDiscountPrice,
              ratings: v.vodEvaluationScore,
              reviews: v.vodEvaluationCount,
              quizzes: 1,
              students: Number(v.studentCount),
              // resources : 100,
              totalHours: ``,
              // + Math.ceil(Number(v.vodDuration.slice(3,5))/60)
              teachers: v.vodProductAdminList,

              isLive: {
                isLive: v.isLive,
                liveCount: v.liveCount,
                liveLimit: v.liveLimit,
                liveWeek: v.liveWeek,
                liveDuration: v.liveDuration,
              },
              // languages: ['Russian', 'Spanish', 'English'],
              // skills: ['Photography', 'Design', 'Art', 'History', 'Museums', 'Creativity', 'Art History'],
              // learnList: [
              //   'A fermentum in morbi pretium aliquam adipiscing donec tempus.',
              //   'Vulputate placerat amet pulvinar lorem nisl.',
              //   'Consequat feugiat habitant gravida quisque elit bibendum id adipiscing sed.',
              //   'Etiam duis lobortis in fames ultrices commodo nibh.',
              //   'Fusce neque. Nulla neque dolor, sagittis eget, iaculis quis, molestie non, velit.',
              //   'Curabitur a felis in nunc fringilla tristique. Praesent congue erat at massa.',
              // ],
            });
          });
          console.log(list);
          setCourseList(list);
        } else {
          if (e.data.header.resultCode == 403) {
            navigate('/auth/duplicated');
          }
          // message.error(e.data.header.resultMessage);
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );

  useEffect(() => {
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    const TeacherIdx = params.get('id');
    setTeacherIdx(TeacherIdx);
    mutateReview({
      URL:
        sessionStorage.getItem('workingEnv') === 'Build'
          ? window.location.host
          : (sessionStorage.getItem('workingEnv') as string),
      adminIdx: Number(TeacherIdx),
    });
  }, []);

  const { mutate: mutateReview } = useMutation(
    (params: WpVodControllerReadAdminAssessParams) => fetcherReview(params),
    {
      onSuccess: (e: any) => {
        console.log(e);
        if (e.data.header.isSuccess) {
          setTeacherAvg(
            e.data.body.data.summary[0]?.adminAssessAvg === undefined
              ? '0.0'
              : e.data.body.data.summary[0]?.adminAssessAvg
          );
          setTeacherCount(
            e.data.body.data.summary[0]?.adminAssessCount === undefined
              ? '0'
              : e.data.body.data.summary[0]?.adminAssessCount
          );
          setReviewList(e.data.body.data.detail?.length === 0 ? [] : e.data.body.data.detail);
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );

  useEffect(() => {
    mutateTeachers({
      adminIdx: TeacherIdx,
      URL:
        sessionStorage.getItem('workingEnv') === 'Build'
          ? window.location.host
          : (sessionStorage.getItem('workingEnv') as string),
      page: 1,
      pageSize: 9,
    });
  }, [TeacherIdx]);

  const { data: Teachers, mutate: mutateTeachers } = useMutation(
    (params: WpVodControllerReadAdminsParams) => fetcherTeachers(params),
    {
      onSuccess: (e: any) => {
        // console.log(e)
        if (e.data.header.isSuccess) {
          console.log(e);
          setTeacherData(e.data.body.data);
        } else {
          if (e.data.header.resultCode == 403) {
            navigate('/auth/duplicated');
          }
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
    console.log();
  };
  const theme = useTheme();

  const carouselSettings = {
    dots: !isMdUp,
    arrows: false,
    slidesToShow: ReviewList?.length === 1 ? 1 : ReviewList?.length === 2 ? 2 : 3,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    ...CarouselDots({
      sx: {
        mt: 8,
      },
    }),
    responsive: [
      {
        // Down md
        breakpoint: theme.breakpoints.values.md,
        settings: { slidesToShow: 2, slidesToScroll: 3 },
      },
      {
        // Down sm
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  return (
    <Container sx={{ py: 2 }}>
      <Typography
        variant="h3"
        sx={{
          display: 'block',
          mb: { xs: 2, md: 5 },
        }}
      >
        {TeacherData[0]?.adminName} Teacher 소개
      </Typography>

      <Grid
        container
        spacing={3}
        // alignItems={{ md: 'center' }}
        pb={3}
        // justifyContent={{ md: 'space-between' }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          lg={3}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          p={'20px !important'}
        >
          <Image
            alt="about"
            src={
              TeacherData[0]?.adminPhotoFilePath === null ||
              TeacherData[0]?.adminPhotoFilePath === ''
                ? '/assets/images/portrait/portrait_1.jpg'
                : (Teachers as any)?.data.body.data[0].adminPhotoFilePath
            }
            // ratio="1/1"
            sx={{
              borderRadius: 2,
              aspectRatio: '3 / 4', // 원하는 비율로 변경
              width: '100%',
              height: 'auto',
            }}
          />
        </Grid>

        <Grid item xs={12} sm={8} lg={9}>
          <Typography variant="h3" sx={{ mb: 3 }}>
            {TeacherData[0]?.adminName}
          </Typography>

          {/* <Stack direction="row" alignItems="center">
            {TeacherData[0]?.teacherCategoryGroupInformation?.map((v: any, i: any) => {
              return (
                <Typography
                  variant="overline"
                  sx={{ color: 'primary.main', ml: 1, mr: 0.5, mb: 4 }}
                >
                  {i === 0 ? '' : ' / '} {v.teacherCategoryInformation.teacherCategoryName}
                </Typography>
              );
            })}
          </Stack> */}

          <Stack
            direction={{ xs: 'column', md: 'column' }}
            spacing={1}
            // sx={{ mt: { xs: 3, md: 3} }}
          >
            <Stack direction="row" alignItems="center" sx={{ marginTop: '16px!important' }}>
              {TeacherData[0]?.adminBirth === null && (
                <Typography variant="body2" sx={{ mr: 0.5, color: '#637381' }}>
                  나이 : 미입력
                </Typography>
              )}
              {TeacherData[0]?.adminBirth !== null && (
                <Typography variant="body2" sx={{ mr: 0.5, color: '#637381' }}>
                  나이 : {TeacherData[0]?.adminBirth}(
                  {new Date().getFullYear() - new Date(TeacherData[0]?.adminBirth).getFullYear()}{' '}
                  세)
                </Typography>
              )}
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography variant="body2" sx={{ mr: 0.5, color: '#637381!important' }}>
                성별 : {TeacherData[0]?.adminGender === 'm' ? '남성' : '여성'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems="center" gap={1}>
              <StarIcon color="warning" />
              <Typography fontWeight={700}>{TeacherAvg}</Typography>
              <Typography>({TeacherCount} reviews)</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="body2"
                sx={{ ml: 1, mr: 0.5, mt: 3, color: '#637381!important' }}
              >
                {TeacherData[0]?.adminComment}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Divider />

      <Grid
        container
        spacing={2}
        alignItems={{ md: 'center' }}
        justifyContent={{ md: 'space-between' }}
      >
        <Grid item xs={12}>
          <Stack
            // direction={{ xs: 'column', md: 'row' }}
            sx={{ mt: { xs: 3, md: 3 }, display: 'flex', alignItems: 'center' }}
          >
            <Button
              size="large"
              variant="soft"
              fullWidth
              color="success"
              sx={{ maxWidth: '250px' }}
              onClick={() => {
                mutateCourses({
                  adminIdx: TeacherData[0]?.adminIdx,
                  URL:
                    sessionStorage.getItem('workingEnv') === 'Build'
                      ? window.location.host
                      : (sessionStorage.getItem('workingEnv') as string),
                  searchingKey: 'all',
                });
                setOpen(true);
              }}
            >
              수업 구매하기
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 5, md: 10 }}
            sx={{ mt: { xs: 4, md: 6 } }}
          >
            <Stack
              spacing={3}
              sx={{
                '& div': {
                  '& p': {
                    marginTop: 0,
                    '& span': {
                      color: '#637381!important',
                    },
                  },
                },
              }}
            >
              <Typography fontWeight={700} sx={{ display: 'flex' }}>
                <InfoIcon sx={{ mr: 1 }} /> Details
              </Typography>
              <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
              <div
                dangerouslySetInnerHTML={{ __html: TeacherData[0]?.adminComment2 as string }}
              />
              <Typography fontWeight={700} sx={{ display: 'flex' }}>
                <BusinessCenterIcon sx={{ mr: 1 }} /> Experience of Teaching
              </Typography>
              <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
              <div
                dangerouslySetInnerHTML={{ __html: TeacherData[0]?.adminExperience as string }}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={3}
            sx={{
              '& div': {
                '& p': {
                  marginTop: 0,
                  '& span': {
                    color: '#637381!important',
                  },
                },
              },
            }}
          >
            <Typography fontWeight={700} sx={{ display: 'flex' }}>
              <BadgeIcon sx={{ mr: 1 }} /> Certification
            </Typography>
            <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
            <div
              dangerouslySetInnerHTML={{ __html: TeacherData[0]?.adminCertification as string }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={3}
            sx={{
              '& div': {
                '& p': {
                  marginTop: 0,
                  '& span': {
                    color: '#637381!important',
                  },
                },
              },
            }}
          >
            <Typography fontWeight={700} sx={{ display: 'flex' }}>
              <SchoolIcon sx={{ mr: 1 }} /> Degree
            </Typography>
            <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
            <div dangerouslySetInnerHTML={{ __html: TeacherData[0]?.adminDegree as string }} />
          </Stack>
        </Grid>

        {/* <Grid item xs={12} pb={5}>
          <Stack spacing={3}>
            <Typography fontWeight={700} sx={{ display: 'flex' }}>
              <ArticleIcon sx={{ mr: 1 }} /> More Information
            </Typography>
            <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
            <List sx={{ mt: '11px !important' }}>
              <ListItem sx={{ pl: '1px !important', color: '#637381!important' }}>
                Email : {TeacherData[0]?.adminId}
              </ListItem>
              <ListItem
                sx={{ py: '2px !important', pl: '1px !important', color: '#637381!important' }}
              >
                Phone : {TeacherData[0]?.adminPhone1}
              </ListItem>
            </List>
          </Stack>
        </Grid> */}
        <Container
          sx={{
            pt: 8,
            pb: { xs: 8, md: 10 },
          }}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ md: 'flex-end' }}
            sx={{
              textAlign: { xs: 'center', md: 'unset' },
            }}
          >
            <Stack spacing={3} flexGrow={1}>
              <Typography variant="h3">리뷰</Typography>
              <Typography sx={{ color: 'text.secondary' }}>수강생들의 수업 후기</Typography>
            </Stack>
            {isMdUp && (
              <CarouselArrows
                spacing={2}
                justifyContent="center"
                onNext={handleNext}
                onPrev={handlePrev}
              />
            )}
          </Stack>

          {ReviewList?.length === 0 ? (
            <Box display="flex" flexDirection={'column'} sx={{ pb: 0 }}>
              <Image
                alt="comingsoon"
                src="/assets/illustrations/illustration_comingsoon.svg"
                sx={{
                  my: 0,
                  mx: 'auto',
                  maxWidth: 320,
                }}
              />
              <span style={{ fontSize: 16, fontWeight: 400, margin: 'auto', color: '#9599a7' }}>
                등록된 리뷰가 없습니다.
              </span>
            </Box>
          ) : (
            <Carousel ref={carouselRef} {...carouselSettings}>
              {ReviewList?.map((testimonial: any) => (
                <Box
                  sx={{
                    px: 2,
                    pt: { xs: 1, md: 10 },
                    pb: { xs: 0, md: 15 },
                  }}
                  //  sx={{
                  //   display: 'grid',
                  //   gap: { xs: 3, md: 4 },
                  //   gridTemplateColumns: {
                  //     xs: 'repeat(1, 1fr)',
                  //     md: 'repeat(3, 1fr)',
                  //   },
                  // }}
                >
                  <TestimonialItemTeacher testimonial={testimonial} />
                </Box>
              ))}
            </Carousel>
          )}
        </Container>
      </Grid>
      <SelectProductModal
        Open={Open}
        setOpen={setOpen}
        data={CourseList}
        teacherIdx={TeacherData[0]?.adminIdx}
        teacherName={TeacherData[0]?.adminName}
        photo={TeacherData[0]?.adminPhotoFilePath}
      />
    </Container>
  );
}

// ----------------------------------------------------------------------
