import { useRef,useState,useEffect} from 'react';
// @mui
import { Pagination, Stack ,Grid} from '@mui/material';
// types
import { useNavigate } from "react-router-dom";

import { ICourseProps } from 'src/types/course';
//
import { ElearningCourseItem, ElearningCourseItemSkeleton,ElearningCourseItemCard } from '../item';

// ----------------------------------------------------------------------

type Props = {
  courses: any[];
  loading?: boolean;
  type : string;
  from ?: any;
  teacherIdx ?: any;
  teacherName ?: any;
  photo ?: any;
  wishList ?: any;
  updateWishList ?: any;
  // total : number;
  // page : any;
};

export default function ElearningCourseList({ courses, loading ,type,from,teacherIdx,teacherName,photo,wishList,updateWishList}: Props) {
    /* eslint-disable */

 
  return (
    <>
      {/* <Stack spacing={5}>
        {(loading ? [...Array(9)] : courses).map((course, index) =>
          course ? type === "1" ? (
            <ElearningCourseItem key={course.id} course={course} />
          ) 
          :
          (
            <ElearningCourseItemCard vertical key={course.id} course={course} />
            // <ElearningCourseItem vertical key={course.id} course={course} />
                      ) 

          : (
            <ElearningCourseItemSkeleton key={index} />
          )
        )}
      </Stack> */}
      {type === "1" ? 
      <Stack spacing={5}>
        {(loading ? [...Array(9)] : courses).map((course, index) =>
          course ?       
          (
            <ElearningCourseItem key={course.id} course={course} from={from} teacherIdx={teacherIdx} teacherName={teacherName} photo={photo} wishList={wishList} updateWishList={updateWishList}/>
            // <ElearningCourseItem vertical key={course.id} course={course} />
                      ) 

          : (
            <ElearningCourseItemSkeleton key={index} />
          )
        )}
      </Stack>
      
      :
      
      <Grid container spacing={3}>
        {(loading ? [...Array(9)] : courses).map((course, index) =>
          course ?       
          (
            <Grid item xs={12} sm={4}>
              <ElearningCourseItemCard vertical key={course.id} course={course} wishList={wishList} updateWishList={updateWishList} />

            </Grid>
            // <ElearningCourseItem vertical key={course.id} course={course} />
                      ) 

          : (
            <ElearningCourseItemSkeleton key={index} />
          )
        )}
      </Grid>
      }

      {/* <Pagination
        count={total}
        color="primary"
        size="large"
        onChange={handleChange}
        sx={{
          my: 10,
          '& .MuiPagination-ul': {
            justifyContent: 'center',
          },
        }}
      /> */}
    </>
  );
}
