/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateInitailBoardDto {
  /** 유일생성 게시판에 대한 정의 notice:공지사항, faq: 자주묻는질문, qna: 문의, review: 리뷰 */
  uniqBoard?: 'notice' | 'faq' | 'qna' | 'review';
}

export interface BaseResponseHeader {
  isSuccess: true | false;
  resultCode: any;
  resultMessage: string;
  timestamp: string;
}

export interface BaseResponseDto {
  header: BaseResponseHeader;
}

export interface Actor {
  /** mbox */
  mbox: string;
  /** name */
  name: string;
}

export interface Obj {
  /** objectType */
  objectType: string;
  /** id */
  id: string;
  /** definition */
  definition: object;
}

export interface StatementDto {
  actor: Actor;
  /** verb */
  verb: string;
  object: Obj;
  /** context */
  context: object;
}

export interface DefaultDto {
  URL: string;
}

export interface SigninResponseData {
  /**
   * Access Token
   * @example eyJhbGciOiJIUzI1NiIsInR5cCI6
   */
  token: string;
}

export interface SigninResponseBody {
  data: SigninResponseData;
}

export interface SigninResponseDto {
  header: BaseResponseHeader;
  body: SigninResponseBody;
}

export interface SignPhotoFileUploadDto {
  /** @format binary */
  signPhotoFile?: File;
}

export interface SignFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface SignFileUploadResponseBody {
  data: SignFileUploadData;
}

export interface SignFileUploadResponseDto {
  header: BaseResponseHeader;
  body: SignFileUploadResponseBody;
}

export interface AdminSignupDto {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  adminId: string;
  /**
   * 강사명
   * @example 홍길동
   */
  adminName: string;
  /**
   * 닉네임
   * @example 홍길동
   */
  adminNick?: string;
  /**
   * 연락처1
   * @example 01012345678
   */
  adminPhone1: string;
  /** 프로필사진 */
  adminPhotoFilePath?: string;
  /** Gender:성별 m:남성, f:여성 */
  adminGender?: 'm' | 'f';
  /** 19990501 */
  adminBirth?: string;
  /** 주소 */
  adminAddr?: string;
  /** 상세주소 */
  adminAddrDetail?: string;
  /** 이력서 */
  adminResumeFilePath?: string;
  /** 간단한 소개(text editor) */
  adminComment?: string;
  /** 자세한 소개(text editor) */
  adminComment2?: string;
  /** 자격증(text editor) */
  adminCertification?: string;
  /** 학력(text editor) */
  adminDegree?: string;
  /** 경력(text editor) */
  adminExperience?: string;
  URL: string;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
}

export interface AdminSigninByEmailDto {
  URL: string;
  /** 아이디 */
  adminId: string;
  /** 패스워드 */
  password: string;
}

export interface MemberVerifiedMailDto {
  URL: string;
  /** 아이디 */
  memberId: string;
}

export interface MemberConfirmPasswordDto {
  URL: string;
  /** 아이디 */
  memberId: string;
  /** 비번코드 */
  passwordCode: string;
}

export interface MemberChangePasswordDto {
  URL: string;
  /** 아이디 */
  memberId: string;
  /** 비번코드 */
  passwordCode: string;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
}

export interface MemberVerifiedPhoneDto {
  URL: string;
  /**
   * 연락처1
   * @example 01012345678
   */
  memberPhone1: string;
}

export interface MemberSignupDto {
  /**
   * 회원명
   * @example 홍길동
   */
  memberName?: string;
  /**
   * 닉네임
   * @example 홍길동
   */
  memberNick?: string;
  /**
   * 연락처1
   * @example 01012345678
   */
  memberPhone1?: string;
  /**
   * 연락처2
   * @example 01012345678
   */
  memberPhone2?: string;
  /** 프로필사진 */
  memberPhotoFilePath?: string;
  /** Gender:성별 m:남성, f:여성 */
  memberGender?: 'm' | 'f';
  /** 19990501 */
  memberBirth?: string;
  /** 주소 */
  memberAddr?: string;
  /** 상세주소 */
  memberAddrDetail?: string;
  /** 학교지역 */
  memberSchoolLoc?: string;
  /** 학교명 */
  memberSchoolName?: string;
  /** 학년 */
  memberSchoolGrade?: string;
  /** 교과 학습 여부 */
  memberStudyLevel?: string;
  /** KMO 학습 여부 */
  hasStudiedKmo?: 0 | 1;
  /** 영재교육 학습 여부 */
  hasStudiedGiftedEducation?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 목표 점수 */
  memberTargetScore?: string;
  /** 목표 시험 */
  memberTargetTest?: string;
  /** 중복로그인방지키 */
  memberSigninKey?: string;
  /** 간편로그인키 */
  memberEasySigninKey?: string;
  /**
   * 가입경로
   * @example [{ content: 네이버광고 },{ content: 전단지 }]
   */
  memberJoinPath?: string;
  /**
   * 마케팅 수신동의(휴대폰/이메일)
   * @example 00, 01, 10, 11
   */
  isAgreedMarketing?: string;
  /** 메모 */
  memberMemo?: string;
  URL: string;
  /** 아이디 */
  memberId: string;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
  /** oauth 사용여부 */
  oAuth?: 'k' | 'n' | 'g';
  /** oauth 구분자 */
  oAuthKey?: string;
}

export interface MemberSigninByEmailDto {
  URL: string;
  /** 아이디 */
  memberId: string;
  /** 패스워드 */
  password: string;
}

export interface MemberSigninByKaKaoDto {
  URL: string;
  /** 카카오 로그인 후, 콜백에서 받은 code 값  */
  code: string;
}

export interface MemberSigninByNaverDto {
  URL: string;
  /** 네이버 로그인 후, 콜백에서 받은 access_token 값  */
  access_token: string;
}

export interface CollaborationMailDto {
  URL: string;
  mailTitle: string;
  mailContent: string;
  name: string;
  mail: string;
  phone: string;
  companyName?: string;
  companySite?: string;
}

export interface UpdateAccesskeyDto {
  accesskey: string;
}

export interface UpdateProfileDto {
  profileType: 'phone' | 'profile';
  profileVal: string;
}

export interface RecipientsData {
  /** 메시지 타입 */
  messageType: 'sms' | 'lms';
  /** 수신 번호 */
  recipientNo: string;
  /**
   * 메시지 제목
   * @example test
   */
  title?: string;
  /**
   * 메시지 내용
   * @example test
   */
  body: string;
}

export interface SendMessageDto {
  recipientsData: RecipientsData[];
}

export interface CreateAlimtalkTemplateDto {
  /**
   * 템플릿 이름
   * @example 테스트 템플릿 이름
   */
  templateName: string;
  /**
   * 템플릿 본문
   * @example 테스트 템플릿 본문 #{의미없는숫자}
   */
  templateContent: string;
}

export interface SaveMessageDto {
  /** 메시지 구분 */
  messageType: 'mms' | 'alimtalk';
  /** 템플릿 코드 */
  templateCode?: string;
  /** 템플릿 본문 */
  templateContent?: string;
  /**
   * 자동발송 0:no, 1:yes
   * @example 0
   */
  isAuto?: 0 | 1;
  autoType: 'signup' | 'paid' | 'lessonExpired' | 'qnaAnswered' | 'lessonStart' | 'lessonStarted';
  /** 메시지 IDX */
  messageIdx?: number;
}

export interface AdminPhotoFileUploadDto {
  /** @format binary */
  adminPhotoFile?: File;
}

export interface AdminFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface AdminFileUploadResponseBody {
  data: AdminFileUploadData;
}

export interface AdminFileUploadResponseDto {
  header: BaseResponseHeader;
  body: AdminFileUploadResponseBody;
}

export interface BreakTimeList {
  /**
   * 휴식요일
   * @example all:전체, 0:일,1:월~6:토
   */
  adminBreakTimeWeek: string;
  /**
   * 휴식 시간
   * @example all:전체, 11:00:00
   */
  adminBreakTime: string;
}

export interface CreateAdminBreaktimeDto {
  /** 강사 index */
  adminIdx: number;
  breakTimeList: BreakTimeList[];
}

export interface CreateAdminDto {
  /**
   * 상태 0:대기,7:승인대기,9:탈퇴
   * @example 1
   */
  adminStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  adminId: string;
  /**
   * 강사명
   * @example 홍길동
   */
  adminName: string;
  /**
   * 닉네임
   * @example 홍길동
   */
  adminNick?: string;
  /**
   * 연락처1
   * @example 01012345678
   */
  adminPhone1: string;
  /** 프로필사진 */
  adminPhotoFilePath?: string;
  /** Gender:성별 m:남성, f:여성 */
  adminGender?: 'm' | 'f';
  /** 19990501 */
  adminBirth?: string;
  /** 주소 */
  adminAddr?: string;
  /** 상세주소 */
  adminAddrDetail?: string;
  /** 이력서 */
  adminResumeFilePath?: string;
  /** 간단한 소개(text editor) */
  adminComment?: string;
  /** 자세한 소개(text editor) */
  adminComment2?: string;
  /** 자격증(text editor) */
  adminCertification?: string;
  /** 학력(text editor) */
  adminDegree?: string;
  /** 경력(text editor) */
  adminExperience?: string;
  /** 중복로그인방지키 */
  adminSigninKey?: string;
  /**
   * 권한 0:직원, 8: 중간관리자, 9:총관리자
   * @example 0
   */
  adminRole?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
}

export interface UpdateAdminStatusDto {
  /**
   * 상태 0:대기,7:승인대기,9:탈퇴
   * @example 1
   */
  adminStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** adminIdexes: 1,2,3,4, */
  adminIdexes: string;
}

export interface UpdateAdminDto {
  /**
   * 상태 0:대기,7:승인대기,9:탈퇴
   * @example 1
   */
  adminStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  adminId: string;
  /**
   * 강사명
   * @example 홍길동
   */
  adminName: string;
  /**
   * 닉네임
   * @example 홍길동
   */
  adminNick?: string;
  /**
   * 연락처1
   * @example 01012345678
   */
  adminPhone1: string;
  /** 프로필사진 */
  adminPhotoFilePath?: string;
  /** Gender:성별 m:남성, f:여성 */
  adminGender?: 'm' | 'f';
  /** 19990501 */
  adminBirth?: string;
  /** 주소 */
  adminAddr?: string;
  /** 상세주소 */
  adminAddrDetail?: string;
  /** 이력서 */
  adminResumeFilePath?: string;
  /** 간단한 소개(text editor) */
  adminComment?: string;
  /** 자세한 소개(text editor) */
  adminComment2?: string;
  /** 자격증(text editor) */
  adminCertification?: string;
  /** 학력(text editor) */
  adminDegree?: string;
  /** 경력(text editor) */
  adminExperience?: string;
  /** 중복로그인방지키 */
  adminSigninKey?: string;
  /**
   * 권한 0:직원, 8: 중간관리자, 9:총관리자
   * @example 0
   */
  adminRole?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 패스워드
   * @example 1234
   */
  password?: string;
}

export interface DeleteAdminDto {
  /** adminIdexes: 1,2,3,4, */
  adminIdexes: string;
}

export interface MemberPhotoFileUploadDto {
  /** @format binary */
  memberPhotoFile?: File;
}

export interface MemberFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface MemberFileUploadResponseBody {
  data: MemberFileUploadData;
}

export interface MemberFileUploadResponseDto {
  header: BaseResponseHeader;
  body: MemberFileUploadResponseBody;
}

export interface CreateMemberAssessDto {
  /**
   * 평가 스코어
   * @example 5
   */
  memberAssessScore: 1 | 2 | 3 | 4 | 5;
  /** 평가(선택형) */
  memberAssessTitle: string;
  /** 평가(입력형) */
  memberAssessContent: string;
  /**
   * 어드민에 메시지: 0: 없음, 1:단순문의, 2:신고
   * @example 0
   */
  isMessageToAdmin?: 0 | 1 | 2;
  /** 어드민에 메시지 */
  messageToAdmin?: string;
  /** 라이브 수업일때 보낸다. */
  scheduleIdx?: number;
}

export interface UpdateMemberAssessDto {
  /**
   * 평가 스코어
   * @example 5
   */
  memberAssessScore: 1 | 2 | 3 | 4 | 5;
  /** 평가(선택형) */
  memberAssessTitle: string;
  /** 평가(입력형) */
  memberAssessContent: string;
  /**
   * 어드민에 메시지: 0: 없음, 1:단순문의, 2:신고
   * @example 0
   */
  isMessageToAdmin?: 0 | 1 | 2;
  /** 어드민에 메시지 */
  messageToAdmin?: string;
}

export interface CreateMemberDto {
  /**
   * 회원상태 0:대기,1:휴면,7:승인대기,8:정지,9:탈퇴
   * @example 1
   */
  memberStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  memberId: string;
  /**
   * 회원명
   * @example 홍길동
   */
  memberName?: string;
  /**
   * 닉네임
   * @example 홍길동
   */
  memberNick?: string;
  /**
   * 연락처1
   * @example 01012345678
   */
  memberPhone1?: string;
  /**
   * 연락처2
   * @example 01012345678
   */
  memberPhone2?: string;
  /** 프로필사진 */
  memberPhotoFilePath?: string;
  /** Gender:성별 m:남성, f:여성 */
  memberGender?: 'm' | 'f';
  /** 19990501 */
  memberBirth?: string;
  /** 주소 */
  memberAddr?: string;
  /** 상세주소 */
  memberAddrDetail?: string;
  /** 학교지역 */
  memberSchoolLoc?: string;
  /** 학교명 */
  memberSchoolName?: string;
  /** 학년 */
  memberSchoolGrade?: string;
  /** 교과 학습 여부 */
  memberStudyLevel?: string;
  /** KMO 학습 여부 */
  hasStudiedKmo?: 0 | 1;
  /** 영재교육 학습 여부 */
  hasStudiedGiftedEducation?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 목표 점수 */
  memberTargetScore?: string;
  /** 목표 시험 */
  memberTargetTest?: string;
  /** 중복로그인방지키 */
  memberSigninKey?: string;
  /** 간편로그인키 */
  memberEasySigninKey?: string;
  /**
   * 가입경로
   * @example [{ content: 네이버광고 },{ content: 전단지 }]
   */
  memberJoinPath?: string;
  /**
   * 마케팅 수신동의(휴대폰/이메일)
   * @example 00, 01, 10, 11
   */
  isAgreedMarketing?: string;
  /** 메모 */
  memberMemo?: string;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
}

export interface UpdateMemberStatusDto {
  /**
   * 회원상태 0:대기,1:휴면,7:승인대기,8:정지,9:탈퇴
   * @example 1
   */
  memberStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** memberIdexes: 1,2,3,4, */
  memberIdexes: string;
}

export interface UpdateMemberDto {
  /**
   * 회원상태 0:대기,1:휴면,7:승인대기,8:정지,9:탈퇴
   * @example 1
   */
  memberStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  memberId: string;
  /**
   * 회원명
   * @example 홍길동
   */
  memberName?: string;
  /**
   * 닉네임
   * @example 홍길동
   */
  memberNick?: string;
  /**
   * 연락처1
   * @example 01012345678
   */
  memberPhone1?: string;
  /**
   * 연락처2
   * @example 01012345678
   */
  memberPhone2?: string;
  /** 프로필사진 */
  memberPhotoFilePath?: string;
  /** Gender:성별 m:남성, f:여성 */
  memberGender?: 'm' | 'f';
  /** 19990501 */
  memberBirth?: string;
  /** 주소 */
  memberAddr?: string;
  /** 상세주소 */
  memberAddrDetail?: string;
  /** 학교지역 */
  memberSchoolLoc?: string;
  /** 학교명 */
  memberSchoolName?: string;
  /** 학년 */
  memberSchoolGrade?: string;
  /** 교과 학습 여부 */
  memberStudyLevel?: string;
  /** KMO 학습 여부 */
  hasStudiedKmo?: 0 | 1;
  /** 영재교육 학습 여부 */
  hasStudiedGiftedEducation?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 목표 점수 */
  memberTargetScore?: string;
  /** 목표 시험 */
  memberTargetTest?: string;
  /** 중복로그인방지키 */
  memberSigninKey?: string;
  /** 간편로그인키 */
  memberEasySigninKey?: string;
  /**
   * 가입경로
   * @example [{ content: 네이버광고 },{ content: 전단지 }]
   */
  memberJoinPath?: string;
  /**
   * 마케팅 수신동의(휴대폰/이메일)
   * @example 00, 01, 10, 11
   */
  isAgreedMarketing?: string;
  /** 메모 */
  memberMemo?: string;
  /**
   * 패스워드
   * @example 1234
   */
  password?: string;
}

export interface DeleteMemberDto {
  /** memberIdexes: 1,2,3,4, */
  memberIdexes: string;
}

export interface BoardFileUploadDto {
  /** @format binary */
  file?: File;
}

export interface BoardFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface BoardFileUploadResponseBody {
  data: BoardFileUploadData;
}

export interface BoardFileUploadResponseDto {
  header: BaseResponseHeader;
  body: BoardFileUploadResponseBody;
}

export interface CreateBoardClassDto {
  /** 게시판 타입 noncomment: 댓글X, comment: 댓글 */
  boardClassType: 'noncomment' | 'comment';
  /** 게시판명 */
  boardClassTitle: string;
}

export interface BoardClassData {
  /** board IDX */
  boardClassIdx: number;
  /** 게시판명 */
  boardClassTitle: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface BoardClassBody {
  total: number;
  data: BoardClassData[];
}

export interface BoardClassResponseDto {
  header: BaseResponseHeader;
  body: BoardClassBody;
}

export interface UpdateBoardClassDto {
  /** 게시판 타입 noncomment: 댓글X, comment: 댓글 */
  boardClassType: 'noncomment' | 'comment';
  /** 게시판명 */
  boardClassTitle: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface CreateBaordCategoryDto {
  /** 카테고리명 */
  boardCategoryTitle: string;
  /** 카테고리 사진 */
  boardCategoryPhotoFilePath?: string;
  /** boardClassIdx */
  boardClassIdx: number;
}

export interface BoardCategoryData {
  /** boardCategory IDX */
  boardCategoryIdx: number;
  /** 카테고리명 */
  boardCategoryTitle: string;
  /** 카테고리 사진 */
  boardCategoryPhotoFilePath?: string;
  /** boardClassIdx */
  boardClassIdx: number;
}

export interface BoardCategoryBody {
  total: number;
  data: BoardCategoryData[];
}

export interface BoardCategoryResponseDto {
  header: BaseResponseHeader;
  body: BoardCategoryBody;
}

export interface UpdateBaordCategoryDto {
  /** 카테고리명 */
  boardCategoryTitle: string;
  /** 카테고리 사진 */
  boardCategoryPhotoFilePath?: string;
}

export interface PostData {
  /** post IDX */
  postIdx: number;
  /** 작성자 admin:관리자, member:회원, anonymous:비회원 */
  postWriter: 'admin' | 'member' | 'anonymous';
  /** 타이틀 */
  postTitle: string;
  /** 내용 */
  postContent: string;
  /**
   * 점수
   * @example 0
   */
  postScore?: 1 | 2 | 3 | 4 | 5;
  /** 첨부파일 */
  postFilePath?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /** 뷰 카운트 */
  vCount?: number;
  /**
   * 비회원 학생명
   * @example 홍길동
   */
  anonymousName?: string;
  /**
   * 비회원 이메일
   * @example lydian@lydianlabs.kr
   */
  anonymousEmail: string;
  /**
   * 비회원 전화번호
   * @example 01012345678
   */
  anonymousPhone?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** memberIdx */
  memberIdx: string;
  /** memberId */
  memberId: string;
  /** memberName */
  memberName: string;
  /** memberPhone1 */
  memberPhone1: string;
  /** adminIdx */
  adminIdx: string;
  /** adminId */
  adminId: string;
  /** adminName */
  adminName: string;
  /** boardClassIdx */
  boardClassIdx: string;
  /** boardClassTitle */
  boardClassTitle: string;
  /** boardCategoryIdx */
  boardCategoryIdx: string;
  /** boardCategoryTitle */
  boardCategoryTitle: string;
  /** boardCategoryPhotoFilePath */
  boardCategoryPhotoFilePath: string;
}

export interface PostBody {
  total: number;
  data: PostData[];
}

export interface PostResponseDto {
  header: BaseResponseHeader;
  body: PostBody;
}

export interface CreatePostDto {
  /** 타이틀 */
  postTitle: string;
  /** 내용 */
  postContent: string;
  /**
   * 점수
   * @example 0
   */
  postScore?: 1 | 2 | 3 | 4 | 5;
  /** 첨부파일 */
  postFilePath?: string;
  /**
   * 공지일때만 0:메인노출안함, 1:메인노출함
   * @example 0
   */
  onMain?: 0 | 1;
  /**
   * 공지일때만 0:일반 공지, 1:이벤트 공지
   * @example 0
   */
  isEvent?: 0 | 1;
  /** boardClassIdx */
  boardClassIdx: number;
  /** boardCategoryIdx */
  boardCategoryIdx: number;
  /** vodProductIdx */
  vodProductIdx?: string;
  /** lessonIdx */
  lessonIdx?: string;
  /** vodProductContentIdx */
  vodProductContentIdx?: string;
}

export interface UpdatePostDto {
  /** 타이틀 */
  postTitle: string;
  /** 내용 */
  postContent: string;
  /**
   * 점수
   * @example 0
   */
  postScore?: 1 | 2 | 3 | 4 | 5;
  /** 첨부파일 */
  postFilePath?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /**
   * 공지일때만 0:메인노출안함, 1:메인노출함
   * @example 0
   */
  onMain?: 0 | 1;
  /**
   * 공지일때만 0:일반 공지, 1:이벤트 공지
   * @example 0
   */
  isEvent?: 0 | 1;
  /** boardClassIdx */
  boardClassIdx: number;
  /** boardCategoryIdx */
  boardCategoryIdx: number;
}

export interface CreateCommentDto {
  /** 내용 */
  commentContent: string;
  /** 첨부파일 */
  commentFilePath?: string;
  /** postIdx */
  postIdx: number;
}

export interface UpdateCommentDto {
  /** 내용 */
  commentContent: string;
  /** 첨부파일 */
  commentFilePath?: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface CreateCocommentDto {
  /** 내용 */
  cocommentContent: string;
  /** 첨부파일 */
  cocommentFilePath?: string;
  /** commentIdx */
  commentIdx: number;
}

export interface UpdateCocommentDto {
  /** 내용 */
  cocommentContent: string;
  /** 첨부파일 */
  cocommentFilePath?: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export type BbbCallbackDto = object;

export type KollusCallbackUploadDto = object;

export type KollusCallbackDto = object;

export interface GetUploadUrlDto {
  /** 입력한 제목을 컨텐츠의 제목으로 강제지정합니다. 이 값을 보내지 않거나 빈 값으로 보내면 기본적으로 파일명이 제목으로 사용됩니다. */
  title: string;
  /** 암호화 업로드 여부 0: false, 1: true */
  is_encryption_upload: '0' | '1';
  /** 패스스로우 여부 0: false, 1: true */
  is_passthrough?: '0' | '1';
  /** 태그 타이틀 들을 입력한다(주의: undefined가 아닌 빈값은 태그를 삭제한다) */
  tagTitle?: string;
}

export type VodUploadUrlResult = object;

export interface VodUploadUrlBody {
  data: VodUploadUrlResult;
}

export interface VodUploadUrlResponseDto {
  header: BaseResponseHeader;
  body: VodUploadUrlBody;
}

export interface UpdateKollusMediaDto {
  vodHistoryFileIdx: number;
  /** 태그 타이틀 들을 입력한다(주의: undefined가 아닌 빈값은 태그를 삭제한다) */
  tagTitle?: string;
}

export interface DeleteKollusMediaDto {
  /** vodHistoryFileIdexes: 1,2,3,4, */
  vodHistoryFileIdexes: string;
}

export interface VodFileUploadDto {
  /** @format binary */
  vodPhotoFile?: File;
}

export interface VodFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface VodFileUploadResponseBody {
  data: VodFileUploadData;
}

export interface VodFileUploadResponseDto {
  header: BaseResponseHeader;
  body: VodFileUploadResponseBody;
}

export interface CreateReferenceDto {
  /** 자료명 */
  fileTitle: string;
  /** productData: 상품자료, productImage: 상품이미지 */
  fileType?: 'productData' | 'productImage';
  /** 자료 */
  filePath: string;
  /** 상품카테고리IDX */
  vodProductCategoryIdx?: string;
  /** 상품 서브 카테고리IDX */
  vodProductSubCategoryIdx?: string;
  /** 상품 서브서브 카테고리IDX */
  vodProductSubSubCategoryIdx?: string;
  /** 상품 서브서브서브 카테고리IDX */
  vodProductSubSubSubCategoryIdx?: string;
  /** 상품 서브서브서브서브 카테고리IDX */
  vodProductSubSubSubSubCategoryIdx?: string;
}

export interface UpdateReferenceConnectionDto {
  /** fileIdexes: 1,2,3,4/ null이면 일괄 해제 */
  fileIdexes: string;
  /** 연결대상 */
  connectionKey: 'vodProduct' | 'vodProductChapter' | 'vodProductContent';
  /** 연결대상의 INDEX */
  connectionIdx: number;
}

export interface DeleteReferenceDto {
  /** fileIdexes: 1,2,3,4, */
  fileIdexes: string;
}

export interface CreateLectureNoteDto {
  /** 강의노트명 */
  lectureNoteTitle: string;
  /** 강의노트 */
  lectureNoteContent?: string;
  /** 상품카테고리IDX */
  vodProductCategoryIdx?: number;
  /** 상품 서브 카테고리IDX */
  vodProductSubCategoryIdx?: number;
  /** 상품 서브서브 카테고리IDX */
  vodProductSubSubCategoryIdx?: number;
  /** 상품 서브서브서브 카테고리IDX */
  vodProductSubSubSubCategoryIdx?: number;
  /** 상품 서브서브서브서브 카테고리IDX */
  vodProductSubSubSubSubCategoryIdx?: number;
}

export interface DeleteLectureNoteDto {
  /** lectureNoteIdexes: 1,2,3,4, */
  lectureNoteIdexes: string;
}

export interface CreateVodProductCategoryDto {
  /** VOD상품 카테고리 */
  vodProductCategoryTitle: string;
  /** VOD상품 카테고리 사진 */
  vodProductCategoryPhotoFilePath?: string;
  /** VOD상품 카테고리 설명 */
  vodProductCategoryDesc?: string;
}

export interface CreateVodProductSubCategoryDto {
  /** VOD상품 카테고리 */
  vodProductSubCategoryTitle: string;
  /** VOD상품 카테고리 사진 */
  vodProductSubCategoryPhotoFilePath?: string;
  /** VOD상품 카테고리 설명 */
  vodProductSubCategoryDesc?: string;
  /** 부모 상품카테고리IDX */
  vodProductCategoryIdx: number;
}

export interface CreateVodProductSubSubCategoryDto {
  /** VOD상품 카테고리 */
  vodProductSubSubCategoryTitle: string;
  /** VOD상품 카테고리 사진 */
  vodProductSubSubCategoryPhotoFilePath?: string;
  /** 서브 상품카테고리IDX */
  vodProductSubCategoryIdx: number;
}

export interface CreateVodProductSubSubSubCategoryDto {
  /** VOD상품 카테고리 */
  vodProductSubSubSubCategoryTitle: string;
  /** VOD상품 카테고리 사진 */
  vodProductSubSubSubCategoryPhotoFilePath?: string;
  /** 서브 상품카테고리IDX */
  vodProductSubSubCategoryIdx: number;
}

export interface CreateVodProductSubSubSubSubCategoryDto {
  /** VOD상품 카테고리 */
  vodProductSubSubSubSubCategoryTitle: string;
  /** VOD상품 카테고리 사진 */
  vodProductSubSubSubSubCategoryPhotoFilePath?: string;
  /** 서브 상품카테고리IDX */
  vodProductSubSubSubCategoryIdx: number;
}

export interface CreateVodProductDto {
  /** 상품 썸네일 */
  vodProductPhotoFilePath?: string;
  /**
   * 상품명
   * @example 주니어 레벨1
   */
  vodProductTitle?: string;
  /**
   * 상품 난이도 1:Beginner,2:Intermediate,3:Expert
   * @example 1
   */
  vodProductLevel?: 1 | 2 | 3;
  /**
   * 이 강의로 얻을수 있는 스킬
   * @example [ReactJS, NextJS]
   */
  vodProductSkills?: string;
  /**
   * 강의목표
   * @example [react를 익힌다, next를 익힌다]
   */
  vodProductGoal?: string;
  /** 강의 한줄 소개 */
  vodProductInfo?: string;
  /** 강의 상세 소개 섹션1 타입 */
  vodProductInfoSection1Type?: string;
  /** 강의 상세 소개 섹션1 타이틀 */
  vodProductInfoSection1Title?: string;
  /** 강의 상세 소개 섹션1 이미지 */
  vodProductInfoSection1Image?: string;
  /** 강의 상세 소개 섹션1 */
  vodProductInfoSection1?: string;
  /** 강의 상세 소개 섹션2 타입 */
  vodProductInfoSection2Type?: string;
  /** 강의 상세 소개 섹션2 타이틀 */
  vodProductInfoSection2Title?: string;
  /** 강의 상세 소개 섹션2 이미지 */
  vodProductInfoSection2Image?: string;
  /** 강의 상세 소개 섹션2 */
  vodProductInfoSection2?: string;
  /** 강의 상세 소개 섹션3 타입 */
  vodProductInfoSection3Type?: string;
  /** 강의 상세 소개 섹션3 타이틀 */
  vodProductInfoSection3Title?: string;
  /** 강의 상세 소개 섹션3 이미지 */
  vodProductInfoSection3Image?: string;
  /** 강의 상세 소개 섹션3 */
  vodProductInfoSection3?: string;
  /** 가격 */
  vodProductPrice?: number;
  /** 할인율(null 또는 기간지날경우 노출안함) */
  vodProductDiscountRate?: string;
  /**
   * 할인 마감일
   * @format date-time
   * @example 2023-03-31 00:00:00
   */
  vodProductDiscountPeriod?: string;
  /** 할인된 가격 */
  vodProductDiscountPrice?: number;
  /**
   * 수강기간: 0:무제한,1개월 ...
   * @example 1
   */
  vodProductPeriod?:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;
  /** MO여부 0아님, 1맞음 */
  isMo?: 0 | 1;
  /**
   * 상품상태 0:활성,9:비활성
   * @example 0
   */
  vodProductStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 라이브 수업인 경우 1을 사용한다.
   * @example 0
   */
  isLive?: 0 | 1;
  /**
   * 티켓 수업인 경우 1을 사용한다.
   * @example 0
   */
  isLiveTicket?: 0 | 1;
  /**
   * 주당 티켓 사용횟수(0:무제한, 1: 주1회), 티켓만료일이 세팅됨
   * @example 0
   */
  useTicketWeekly?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  /**
   * 티켓 수업인데 강사가지정된 경우 1을 사용한다.
   * @example 0
   */
  isLiveAdminFixed?: 0 | 1;
  /** 라이브선택시: 회차 */
  liveCount?: number;
  /** 라이브선택시: 요일(0:일~6:토) */
  liveWeek?: string;
  /** 라이브선택시: 회당강의분수 */
  liveDuration?: string;
  /** 그룹수업제한인원: 1: 1:1, 2: 1:2 */
  liveLimit?: string;
  /**
   * 라이브 트라이얼일 경우 1을 사용한다.
   * @example 0
   */
  liveTrial?: 0 | 1;
  /** 상품 카테고리IDX */
  vodProductCategoryIdx?: number;
  /** 상품 서브 카테고리IDX */
  vodProductSubCategoryIdx?: number;
  /** 상품 서브서브 카테고리IDX */
  vodProductSubSubCategoryIdx?: number;
  /** 상품 서브서브서브 카테고리IDX */
  vodProductSubSubSubCategoryIdx?: number;
  /** 상품 서브서브서브서브 카테고리IDX */
  vodProductSubSubSubSubCategoryIdx?: number;
  /** adminIndexes: 1,2,3,4 */
  adminIndexes?: string;
  /** 태그 타이틀 들을 입력한다(주의: undefined가 아닌 빈값은 태그를 삭제한다) */
  tagTitle?: string;
}

export interface UpdateVodProductStatusDto {
  /**
   * 상품상태 0:활성,9:비활성
   * @example 0
   */
  vodProductStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 상품IDX */
  vodProductIndexes: string;
}

export interface UpdateVodProductDto {
  /** 상품 썸네일 */
  vodProductPhotoFilePath?: string;
  /**
   * 상품명
   * @example 주니어 레벨1
   */
  vodProductTitle?: string;
  /**
   * 상품 난이도 1:Beginner,2:Intermediate,3:Expert
   * @example 1
   */
  vodProductLevel?: 1 | 2 | 3;
  /**
   * 이 강의로 얻을수 있는 스킬
   * @example [ReactJS, NextJS]
   */
  vodProductSkills?: string;
  /**
   * 강의목표
   * @example [react를 익힌다, next를 익힌다]
   */
  vodProductGoal?: string;
  /** 강의 한줄 소개 */
  vodProductInfo?: string;
  /** 강의 상세 소개 섹션1 타입 */
  vodProductInfoSection1Type?: string;
  /** 강의 상세 소개 섹션1 타이틀 */
  vodProductInfoSection1Title?: string;
  /** 강의 상세 소개 섹션1 이미지 */
  vodProductInfoSection1Image?: string;
  /** 강의 상세 소개 섹션1 */
  vodProductInfoSection1?: string;
  /** 강의 상세 소개 섹션2 타입 */
  vodProductInfoSection2Type?: string;
  /** 강의 상세 소개 섹션2 타이틀 */
  vodProductInfoSection2Title?: string;
  /** 강의 상세 소개 섹션2 이미지 */
  vodProductInfoSection2Image?: string;
  /** 강의 상세 소개 섹션2 */
  vodProductInfoSection2?: string;
  /** 강의 상세 소개 섹션3 타입 */
  vodProductInfoSection3Type?: string;
  /** 강의 상세 소개 섹션3 타이틀 */
  vodProductInfoSection3Title?: string;
  /** 강의 상세 소개 섹션3 이미지 */
  vodProductInfoSection3Image?: string;
  /** 강의 상세 소개 섹션3 */
  vodProductInfoSection3?: string;
  /** 가격 */
  vodProductPrice?: number;
  /** 할인율(null 또는 기간지날경우 노출안함) */
  vodProductDiscountRate?: string;
  /**
   * 할인 마감일
   * @format date-time
   * @example 2023-03-31 00:00:00
   */
  vodProductDiscountPeriod?: string;
  /** 할인된 가격 */
  vodProductDiscountPrice?: number;
  /**
   * 수강기간: 0:무제한,1개월 ...
   * @example 1
   */
  vodProductPeriod?:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;
  /** 쿠폰사용가능여부 1:가능, 0:불가 */
  isCoupon?: 0 | 1;
  /** 추천여부 0:미추천, 1추천 */
  isRecommend?: 0 | 1;
  /** MO여부 0아님, 1맞음 */
  isMo?: 0 | 1;
  /**
   * 상품상태 0:활성,9:비활성
   * @example 0
   */
  vodProductStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 티켓 수업인 경우 1을 사용한다.
   * @example 0
   */
  isLiveTicket?: 0 | 1;
  /**
   * 주당 티켓 사용횟수(0:무제한, 1: 주1회), 티켓만료일이 세팅됨
   * @example 0
   */
  useTicketWeekly?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  /**
   * 티켓 수업인데 강사가지정된 경우 1을 사용한다.
   * @example 0
   */
  isLiveAdminFixed?: 0 | 1;
  /**
   * 라이브 트라이얼일 경우 1을 사용한다.
   * @example 0
   */
  liveTrial?: 0 | 1;
  /** 상품카테고리IDX */
  vodProductCategoryIdx?: number;
  /** 상품 서브 카테고리IDX */
  vodProductSubCategoryIdx?: number;
  /** 상품 서브서브 카테고리IDX */
  vodProductSubSubCategoryIdx?: number;
  /** 상품 서브서브서브 카테고리IDX */
  vodProductSubSubSubCategoryIdx?: number;
  /** 상품 서브서브서브서브 카테고리IDX */
  vodProductSubSubSubSubCategoryIdx?: number;
  /** adminIndexes: 1,2,3,4 / null이면 삭제 */
  adminIndexes?: string;
  /** 태그 타이틀 들을 입력한다(주의: undefined가 아닌 빈값은 태그를 삭제한다) */
  tagTitle?: string;
}

export interface DeleteVodProductDto {
  /** vodProductIndexes: 1,2,3,4, */
  vodProductIndexes: string;
}

export interface CreateVodProductChapterDto {
  /** 챕터명 */
  vodProductChapterTitle?: string;
  /** VOD상품 챕터 사진 */
  vodProductChapterPhotoFilePath?: string;
  /** 상품 INDEX */
  vodProductIdx: number;
}

export interface UpdateVodProductChapterDto {
  /** 챕터명 */
  vodProductChapterTitle?: string;
  /** VOD상품 챕터 사진 */
  vodProductChapterPhotoFilePath?: string;
  /** 순서를 변경했다면, 해당 챕터의 모든 vodProductChapterIdx 변경 순서대로 보낸다. */
  vodProductChapterIdexes?: string;
}

export interface DeleteVodProductChapterDto {
  /** vodProductChapterIndexes: 1,2,3,4, */
  vodProductChapterIndexes: string;
}

export interface CreateVodProductContentDto {
  /** 컨텐츠명 */
  vodProductContentTitle?: string;
  /**
   * 컨텐츠 타입
   * @example VOD
   */
  vodContentType?: 'VOD' | 'HyFlex' | 'TEST';
  /** 0보다 크면 시험시간을 사용으로한다(분) */
  useTimer?: number;
  /** 0보다 크면, quizIdx의 문제 중 자동으로 개수만큼 문제를 추천한다. */
  useAutoQuiz?: number;
  /** 0미사용, 1사용 */
  useHint?: number;
  /** 답변등록후 힌트 사용여부 0미사용, 1사용 */
  useHintAfterAnswered?: number;
  /** 시험지제출후 수정불가 여부 1불가, 0가능 */
  useChangeAfterSubmited?: number;
  /** 챕터 INDEX */
  vodProductChapterIdx: number;
  /** 파일 INDEX */
  vodHistoryFileIdx?: number;
  /** 퀴즈 INDEX */
  quizIdx?: number;
  /** 강의노트 INDEX */
  lectureNoteIdx?: number;
  /** 메타러닝 퀴즈 INDEX */
  metaLearningQuizIdx?: number;
  /** 어뎁티브러닝에 들어갈 동영상 */
  adaptiveLearningVodHistoryFileIndexes?: string;
}

export interface UpdateVodProductContentDto {
  /** 컨텐츠명 */
  vodProductContentTitle?: string;
  /**
   * 컨텐츠 타입
   * @example VOD
   */
  vodContentType?: 'VOD' | 'HyFlex' | 'TEST';
  /** 0보다 크면 시험시간을 사용으로한다(분) */
  useTimer?: number;
  /** 0보다 크면, quizIdx의 문제 중 자동으로 개수만큼 문제를 추천한다. */
  useAutoQuiz?: number;
  /** 0미사용, 1사용 */
  useHint?: number;
  /** 답변등록후 힌트 사용여부 0미사용, 1사용 */
  useHintAfterAnswered?: number;
  /** 시험지제출후 수정불가 여부 1불가, 0가능 */
  useChangeAfterSubmited?: number;
  /** 파일 INDEX */
  vodHistoryFileIdx?: number;
  /** 순서를 변경했다면, 해당 챕터의 모든 vodProductContentIdx 변경 순서대로 보낸다. */
  vodProductContentIdexes?: string;
  /** 퀴즈 INDEX */
  quizIdx?: number;
  /** 강의노트 INDEX */
  lectureNoteIdx?: number;
  /** 메타러닝 퀴즈 INDEX */
  metaLearningQuizIdx?: number;
  /** 어뎁티브러닝에 들어갈 동영상 */
  adaptiveLearningVodHistoryFileIndexes?: string;
}

export interface DeleteVodProductContentDto {
  /** vodProductContentIdexes: 1,2,3,4, */
  vodProductContentIndexes: string;
}

export interface CheckLiveDto {
  /** 상품 idx */
  vodProductIdx: number;
  /** 강사 IDX */
  adminIdx: number;
  /** 회원 IDX */
  memberIndexes: string;
  /** 2024-06-11 */
  startDate: string;
  /** 10 */
  time: string;
}

export interface CreatelessonDto {
  /** VOD수업 INDEX */
  vodProductIdx: number;
  /**
   * 개강일
   * @format date-time
   * @example 2023-03-01
   */
  lessonMemberStartDate: string;
  /**
   * 종강일
   * @format date-time
   * @example 2023-03-31
   */
  lessonMemberEndDate?: string;
  /** 학생 INDEX */
  memberIndexes: string;
  /** 라이브일때만 강사 INDEX */
  adminIdx?: number;
  /**
   * 라이브일때만 time
   * @example 0000
   */
  time?: string;
}

export interface AddMemberDto {
  /** VOD수업 INDEX */
  lessonIdx: number;
  /**
   * 개강일
   * @format date-time
   * @example 2023-03-01
   */
  lessonMemberStartDate: string;
  /** 학생 INDEX */
  memberIndexes: string;
}

export interface UpdateLessonMemberDto {
  /** VOD수업 INDEX */
  lessonMemberIdx: number;
  /**
   * 종강일
   * @format date-time
   * @example 2023-03-31
   */
  lessonMemberEndDate?: string;
  /** 레슨상태 0:수강,8:종강,9:수강취소 */
  lessonMemberStatus?: 8 | 9;
}

export interface CheckChangeScheduleDto {
  /** 스케쥴 idx */
  scheduleIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time: string;
}

export interface ChangeScheduleDto {
  /** 스케쥴 idx */
  scheduleIdx: number;
  /** 20240611 */
  startDate: string;
  /** 1000 */
  time: string;
  /** 변경신청사유 */
  changedReason?: string;
}

export interface ConfirmChangeScheduleDto {
  /** 1:승인, 2:거부 */
  isConfirm: 1 | 2;
  /** 거부사유 */
  rejectedReason?: string;
}

export interface AddScheduleDto {
  /** 마지막 scheduleIdx를 전송 */
  scheduleIdx: number;
  /** 20240611 */
  startDate: string;
}

export interface UpdateScheduleDto {
  /** 변경할 scheduleIdx를 전송 */
  scheduleIdx: number;
  /** 20240611 */
  startDate: string;
}

export interface ScheduleList {
  scheduleIdx: number;
  memberIdx: number;
  memberId: string;
}

export interface CreateLarahvDto {
  lessonIdx: number;
  /** @example 2024-09-25T01:00:00.000Z */
  liveStartDate: string;
  liveEndDate: string;
  schedules: ScheduleList[];
}

export interface GetLarahvRecordDto {
  liveInternalMeetingId?: string;
  liveExternalMeetingId?: string;
  /** @example 2024-09-25 */
  searchingStartDate: string;
  /** @example 2024-09-25 */
  searchingEndDate: string;
}

export interface ConnetionTagDto {
  key: 'vodProduct' | 'quiz' | 'question' | 'vodHistoryFile';
  idx: number;
  /** 만약 빈값을 보내면 연결해제됨 */
  tagTitle: string;
}

export interface CreateCouponDto {
  /** 쿠폰명 */
  couponName: string;
  /**
   * 쿠폰만료일
   * @format date-time
   * @example 2023-03-31
   */
  couponExpiredDate?: string;
  /**
   * 쿠폰타입 amount:금액, percent:비율
   * @example amount
   */
  couponType?: 'amount' | 'percent';
  /**
   * 쿠폰금액 amount:금액, percent: 1~100
   * @example 1100
   */
  couponValue: string;
  /** 발매 수량 */
  couponQuantity: number;
  /** 회원 INDEX */
  memberIdx?: number;
}

export interface UpdateCouponDto {
  /** INDEX */
  memberIdx: number;
}

export interface AddCouponDto {
  /** 발매 수량 */
  couponQuantity: number;
}

export interface PaymentRefundDto {
  /**
   * 환불금액(부가세 포함)
   * @example 1100
   */
  refundedAmount: number;
}

export interface AddLiveTicketDto {
  /** VOD수업 INDEX */
  lessonIdx: number;
  /** 2024-06-11 */
  liveTicketStartDate: string;
  /** 2024-06-11 */
  liveTicketEndDate: string;
  /** 티켓 강사 지정 */
  adminIdx?: number;
}

export interface TemplateFileUploadDto {
  /** @format binary */
  templateFile?: File;
}

export interface TemplateFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface TemplateFileUploadResponseBody {
  data: TemplateFileUploadData;
}

export interface TemplateFileUploadResponseDto {
  header: BaseResponseHeader;
  body: TemplateFileUploadResponseBody;
}

export interface SaveMenuDto {
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
  /** 노출순서 */
  menuNo?: number;
  /** 메뉴 경로 */
  menuPath?: string;
  /** 메뉴명 */
  menuTitle?: string;
}

export interface SaveSubMenuDto {
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
  /** 노출순서 */
  subMenuNo?: number;
  /** 메뉴 경로 */
  subMenuPath?: string;
  /** 메뉴명 */
  subMenuTitle?: string;
  /** 상위메뉴 */
  menuIdx: number;
}

export interface SaveTemplateDto {
  templateTitle?: string;
  /** 정보1 */
  templateContent?: string;
  /** 정보2 */
  templateContent2?: string;
  /** 상위메뉴(메뉴/서브메뉴 둘중하나만)/ 업데이트시엔 필요없음 */
  menuIdx?: number;
  /** 상위메뉴(메뉴/서브메뉴 둘중하나만)/ 업데이트시엔 필요없음 */
  subMenuIdx?: number;
}

export interface QuizPhotoFileUploadDto {
  /** @format binary */
  photoFile?: File;
}

export interface QuizFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface QuizFileUploadResponseBody {
  data: QuizFileUploadData;
}

export interface QuizFileUploadResponseDto {
  header: BaseResponseHeader;
  body: QuizFileUploadResponseBody;
}

export interface CreateQuestionDto {
  /** 문제타입 */
  questionType?: '객관식' | '주관식';
  /**
   * 문제 점수
   * @example 0
   */
  questionPoint?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /** 문제 레벨 */
  questionLevel?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /** 문제명 */
  questionTitle?: string;
  /** 문제내용 */
  questionContent?: string;
  /**
   * 객관식 보기
   * @example [{ "no": "1", "content": "이거다" },{ "no": "2", "content": "저거다" }]
   */
  questionOpt?: string;
  /** 정답 */
  questionAns?: string;
  /** 문제해설 */
  questionDesc?: string;
  /** 문제힌트 */
  questionHint?: string;
  /** 상품 카테고리IDX */
  vodProductCategoryIdx?: number;
  /** 상품 서브 카테고리IDX */
  vodProductSubCategoryIdx?: number;
  /** 상품 서브서브 카테고리IDX */
  vodProductSubSubCategoryIdx?: number;
  /** 상품 서브서브서브 카테고리IDX */
  vodProductSubSubSubCategoryIdx?: number;
  /** 상품 서브서브서브서브 카테고리IDX */
  vodProductSubSubSubSubCategoryIdx?: number;
  /** 태그 타이틀 */
  tagTitle?: string;
}

export interface UpdateQuestionDto {
  /** 문제타입 */
  questionType?: '객관식' | '주관식';
  /**
   * 문제 점수
   * @example 0
   */
  questionPoint?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /** 문제 레벨 */
  questionLevel?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /** 문제명 */
  questionTitle?: string;
  /** 문제내용 */
  questionContent?: string;
  /**
   * 객관식 보기
   * @example [{ "no": "1", "content": "이거다" },{ "no": "2", "content": "저거다" }]
   */
  questionOpt?: string;
  /** 정답 */
  questionAns?: string;
  /** 문제해설 */
  questionDesc?: string;
  /** 문제힌트 */
  questionHint?: string;
  /** 상품 카테고리IDX */
  vodProductCategoryIdx?: number;
  /** 상품 서브 카테고리IDX */
  vodProductSubCategoryIdx?: number;
  /** 상품 서브서브 카테고리IDX */
  vodProductSubSubCategoryIdx?: number;
  /** 상품 서브서브서브 카테고리IDX */
  vodProductSubSubSubCategoryIdx?: number;
  /** 상품 서브서브서브서브 카테고리IDX */
  vodProductSubSubSubSubCategoryIdx?: number;
  /** 태그 타이틀 */
  tagTitle?: string;
}

export interface DeleteQuestionDto {
  /** @example 1,2,3,4~ */
  questionIndexes: string;
}

export interface ConnetionQuizDto {
  /** @example 1,2,3,4~ */
  questionIndexes: string;
  /** quizIdx */
  quizIdx: number;
}

export interface CreateQuizDto {
  /** 시험지 제목 */
  quizTitle?: string;
  /** 시험지 소개 */
  quizContent?: string;
  /** @example 1,2,3,4~ */
  questionIndexes?: string;
  /** 상품 카테고리IDX */
  vodProductCategoryIdx?: number;
  /** 상품 서브 카테고리IDX */
  vodProductSubCategoryIdx?: number;
  /** 상품 서브서브 카테고리IDX */
  vodProductSubSubCategoryIdx?: number;
  /** 상품 서브서브서브 카테고리IDX */
  vodProductSubSubSubCategoryIdx?: number;
  /** 상품 서브서브서브서브 카테고리IDX */
  vodProductSubSubSubSubCategoryIdx?: number;
  /** 태그 타이틀 들을 입력한다(주의: undefined가 아닌 빈값은 태그를 삭제한다) */
  tagTitle?: string;
}

export interface UpdateQuizDto {
  /** 시험지 제목 */
  quizTitle?: string;
  /** 시험지 소개 */
  quizContent?: string;
  /** 상품 카테고리IDX */
  vodProductCategoryIdx?: number;
  /** 상품 서브 카테고리IDX */
  vodProductSubCategoryIdx?: number;
  /** 상품 서브서브 카테고리IDX */
  vodProductSubSubCategoryIdx?: number;
  /** 상품 서브서브서브 카테고리IDX */
  vodProductSubSubSubCategoryIdx?: number;
  /** 상품 서브서브서브서브 카테고리IDX */
  vodProductSubSubSubSubCategoryIdx?: number;
  /** 태그 타이틀 들을 입력한다(주의: undefined가 아닌 빈값은 태그를 삭제한다) */
  tagTitle?: string;
}

export interface DeleteQuizDto {
  /** @example 1,2,3,4~ */
  quizIndexes: string;
}

export interface WpPhotoFileUploadDto {
  /** @format binary */
  memberPhotoFile?: File;
}

export interface WpFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface WpFileUploadResponseBody {
  data: WpFileUploadData;
}

export interface WpFileUploadResponseDto {
  header: BaseResponseHeader;
  body: WpFileUploadResponseBody;
}

export interface WpUpdateMyInfoDto {
  /**
   * 회원명
   * @example 홍길동
   */
  memberName?: string;
  /**
   * 닉네임
   * @example 홍길동
   */
  memberNick?: string;
  /**
   * 연락처1
   * @example 01012345678
   */
  memberPhone1?: string;
  /**
   * 연락처2
   * @example 01012345678
   */
  memberPhone2?: string;
  /** 프로필사진 */
  memberPhotoFilePath?: string;
  /** 주소 */
  memberAddr?: string;
  /** 상세주소 */
  memberAddrDetail?: string;
  /** 학교지역 */
  memberSchoolLoc?: string;
  /** 학교명 */
  memberSchoolName?: string;
  /** 학년 */
  memberSchoolGrade?: string;
  /** 교과 학습 여부 */
  memberStudyLevel?: string;
  /** KMO 학습 여부 */
  hasStudiedKmo?: 0 | 1;
  /** 영재교육 학습 여부 */
  hasStudiedGiftedEducation?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 목표 점수 */
  memberTargetScore?: string;
  /** 목표 시험 */
  memberTargetTest?: string;
  /**
   * 마케팅 수신동의(휴대폰/이메일)
   * @example 00, 01, 10, 11
   */
  isAgreedMarketing?: string;
}

export interface WpAddKakaoDto {
  /** 카카오 로그인 후, 콜백에서 받은 code 값  */
  code: string;
}

export interface WpAddNaverDto {
  /** 네이버 로그인 후, 콜백에서 받은 access_token 값  */
  access_token: string;
}

export interface WpChangePasswordDto {
  /**
   * 변경 패스워드
   * @example 1234
   */
  changePassword: string;
}

export type WpDeleteDto = object;

export interface WpAddCouponDto {
  /** 쿠폰 코드 */
  couponCode: string;
}

export interface ReadLarahvRecordDto {
  liveInternalMeetingId?: string;
  liveExternalMeetingId?: string;
  /** @example 2024-09-25 */
  searchingStartDate: string;
  /** @example 2024-09-25 */
  searchingEndDate: string;
}

export interface CreateWishlistDto {
  /** VOD상품 INDEX */
  vodProductIdx: number;
}

export interface CheckChangeSchedulesDto {
  /** 레슨 idx */
  lessonIdx: number;
  /** 강사 IDX */
  adminIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time: string;
}

export interface CheckChangeSchedules2Dto {
  /** 레슨 idx */
  lessonIdx: number;
  /** 강사 IDX */
  adminIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time?: string;
  /** 마지막 조회한 내용 */
  lastTime?: string;
}

export interface WpChangeSchedulesDto {
  /** 레슨 idx */
  lessonIdx: number;
  /** 강사 IDX */
  adminIdx: number;
  /** 20240611 */
  startDate: string;
  /** 1000 */
  time: string;
}

export interface WpCheckChangeScheduleDto {
  /** 스케쥴 idx */
  scheduleIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time: string;
}

export interface WpCheckChangeSchedule2Dto {
  /** 스케쥴 idx */
  scheduleIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time?: string;
  /** 마지막 조회한 내용 */
  lastTime?: string;
}

export interface WpChangeScheduleDto {
  /** 스케쥴 idx */
  scheduleIdx: number;
  /** 20240611 */
  startDate: string;
  /** 1000 */
  time: string;
  /** 변경신청사유 */
  changedReason?: string;
}

export interface WpCheckTicketDto {
  /** 라이브티켓 idx */
  liveTicketIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time?: string;
  /** 만약 티켓이 지정강사라면 무시된다. */
  adminIdx?: number;
}

export interface WpCheckTicket2Dto {
  /** 라이브티켓 idx */
  liveTicketIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time?: string;
  /** 만약 티켓이 지정강사라면 무시된다. */
  adminIdx?: number;
  /** 마지막 조회한 내용 */
  lastTime?: string;
}

export interface WpCreateAdminAssessDto {
  /**
   * 강사 평가 스코어
   * @example 5
   */
  adminAssessScore: 1 | 2 | 3 | 4 | 5;
  /** 강사 평가(선택형) */
  adminAssessTitle: string;
  /** 강사 평가(입력형) */
  adminAssessContent: string;
  /**
   * 어드민에 메시지: 0: 없음, 1:단순문의, 2:신고
   * @example 0
   */
  isMessageToAdmin?: 0 | 1 | 2;
  /** 어드민에 메시지 */
  messageToAdmin?: string;
  /** 라이브 수업일때 보낸다. */
  scheduleIdx?: number;
}

export interface WpUpdateAdminAssessDto {
  /**
   * 강사 평가 스코어
   * @example 5
   */
  adminAssessScore: 1 | 2 | 3 | 4 | 5;
  /** 강사 평가(선택형) */
  adminAssessTitle: string;
  /** 강사 평가(입력형) */
  adminAssessContent: string;
  /**
   * 어드민에 메시지: 0: 없음, 1:단순문의, 2:신고
   * @example 0
   */
  isMessageToAdmin?: 0 | 1 | 2;
  /** 어드민에 메시지 */
  messageToAdmin?: string;
}

export interface WpReadReferenceDto {
  vodProductCategoryIndexes?: string;
  vodProductSubCategoryIndexes?: string;
  vodProductSubSubCategoryIndexes?: string;
  vodProductSubSubSubCategoryIndexes?: string;
  vodProductSubSubSubSubCategoryIndexes?: string;
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface CheckProductList {
  /** 상품 IDX */
  vodProductIdx: number;
  /** 쿠폰 IDX */
  couponIdx?: number;
}

export interface WpCheckPaymentDto {
  productList: CheckProductList[];
}

export interface WpCheckLiveDto {
  /** 상품 idx */
  vodProductIdx: number;
  /** 강사 IDX */
  adminIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time: string;
}

export interface WpCheckLive2Dto {
  /** 상품 idx */
  vodProductIdx: number;
  /** 강사 IDX */
  adminIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time?: string;
  /** 마지막 조회한 내용 */
  lastTime?: string;
}

export interface ProductList {
  /** 상품 IDX */
  vodProductIdx: number;
  /** 쿠폰 IDX */
  couponIdx?: number;
  /** 강사 IDX */
  adminIdx?: number;
  /** 20240611 */
  startDate?: string;
  /** 1030 */
  time?: string;
}

export interface WpBeforePaymentDto {
  /** 해외카드결제만 1을 보낸다. */
  isGlobal?: number;
  productList: ProductList[];
}

export interface WpCancelPaymentDto {
  /** 페이플 검증키 */
  PCD_PAY_OID: string;
}

export interface WpAfterPaymentDto {
  /** 페이플 검증키 */
  PCD_PAY_OID: string;
  PCD_AUTH_KEY: string;
  PCD_PAY_REQKEY: string;
  PCD_PAYER_ID: string;
}

export interface WpRefundDto {
  /** 결제IDX */
  paymentIdx: number;
}

export type WpAfterGlobalPaymentDto = object;

export interface WpBoardFileUploadDto {
  /** @format binary */
  photoFile?: File;
}

export interface WpBoardFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface WpBoardFileUploadResponseBody {
  data: WpBoardFileUploadData;
}

export interface WpBoardFileUploadResponseDto {
  header: BaseResponseHeader;
  body: WpBoardFileUploadResponseBody;
}

export interface CreateWpPostDto {
  /** 타이틀 */
  postTitle: string;
  /** 내용 */
  postContent: string;
  /**
   * 점수
   * @example 0
   */
  postScore?: 1 | 2 | 3 | 4 | 5;
  /** 첨부파일 */
  postFilePath?: string;
  /**
   * 0:다들보임, 1:나만보임
   * @example 0
   */
  isSecret?: 0 | 1;
  /** boardClassIdx */
  boardClassIdx: number;
  /** boardCategoryIdx */
  boardCategoryIdx: number;
  /** 상품에 대한 질문일 경우 */
  vodProductIdx?: number;
  /** 수업에 대한 질문일 경우 */
  lessonIdx?: number;
  /** vodProductContentIdx */
  vodProductContentIdx?: number;
}

export interface UpdateWpPostDto {
  /** 타이틀 */
  postTitle: string;
  /** 내용 */
  postContent: string;
  /**
   * 점수
   * @example 0
   */
  postScore?: 1 | 2 | 3 | 4 | 5;
  /** 첨부파일 */
  postFilePath?: string;
  /**
   * 0:다들보임, 1:나만보임
   * @example 0
   */
  isSecret?: 0 | 1;
}

export interface CreateWpCommentDto {
  /** 내용 */
  commentContent: string;
  /** 첨부파일 */
  commentFilePath?: string;
  /** postIdx */
  postIdx: number;
}

export interface UpdateWpCommentDto {
  /** 내용 */
  commentContent: string;
  /** 첨부파일 */
  commentFilePath?: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface CreateWpCocommentDto {
  /** 내용 */
  cocommentContent: string;
  /** 첨부파일 */
  cocommentFilePath?: string;
  /** commentIdx */
  commentIdx: number;
}

export interface UpdateWpCocommentDto {
  /** 내용 */
  cocommentContent: string;
  /** 첨부파일 */
  cocommentFilePath?: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface Answered {
  /** 문제 INDEX */
  questionIdx: number;
  /** 회원 입력값 */
  inputAns?: string;
  /** 문제풀이시간 */
  duration: number;
  /** 시험지제출후 수정불가 여부 1불가, 0가능 */
  useChangeAfterSubmited?: number;
}

export interface CreateLevelTestAnswerDto {
  /** vodProductIdx */
  vodProductIdx: number;
  answered: Answered[];
}

export interface CreateAnswerDto {
  /** scheduleIdx */
  scheduleIdx?: number;
  /** 시험지 INDEX */
  quizIdx: number;
  answered: Answered[];
}

export interface AnalysisWpQuizHistoryDto {
  quizHistoryGroup?: string;
}

export interface SystemControllerReadTrafficStatisticsParams {
  /**
   * 검색 월
   * @example 2023-02
   */
  searchingDate: string;
}

export interface SystemControllerStatisticsHistoryLiveParams {
  /**
   * 검색 기간(시작일)
   * @example 2023-02-01
   */
  searchingStartDate: string;
  /**
   * 검색 기간(종료일)
   * @example 2023-02-01
   */
  searchingEndDate: string;
  /** adminIndexes */
  adminIndexes?: string;
}

export interface SignControllerDuplicatedAdminParams {
  URL: string;
  /** 검색컬럼명 */
  searchingKey: 'adminId' | 'adminNick';
  /** 검색값 */
  searchingVal: string;
}

export interface SignControllerDuplicatedMemberParams {
  URL: string;
  /** 검색컬럼명 */
  searchingKey: 'memberId' | 'memberNick';
  /** 검색값 */
  searchingVal: string;
}

export interface MessageControllerReadMessageParams {
  /** 수신번호 */
  recipientNo?: string;
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface MessageControllerReadAlimtalkTemplateParams {
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface AdminControllerReadAdminAssessParams {
  /** 특정 회원 것만 조회 */
  memberIdx?: number;
  /** 특정 강사 것만 조회 */
  adminIdx?: number;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface AdminControllerReadTeacherBreaktimeParams {
  /** 강사 index */
  adminIdx?: number;
  /** 0,1,2,3,4,5,6 */
  week?: string;
}

export interface AdminControllerReadAdminsParams {
  /** 상태 0:대기,7:승인대기,9:탈퇴 */
  adminStatus?: string;
  /** Gender:성별 m:남성, f:여성 */
  adminGender?: string;
  adminAgeMin?: string;
  adminAgeMax?: string;
  /** 검색컬럼명 */
  searchingKey: 'adminIdx' | 'adminId' | 'adminName' | 'adminNick' | 'adminPhone1';
  /** 검색값 */
  searchingVal: string;
  /** sorting 컬럼명 */
  sortBy?: 'adminIdx' | 'adminId' | 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface MemberControllerReadMemberAssessParams {
  /** 특정 회원 것만 조회 */
  memberIdx?: number;
  /** 특정 강사 것만 조회 */
  adminIdx?: number;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface MemberControllerDuplicatedMemberParams {
  /** 검색컬럼명 */
  searchingKey: 'memberId' | 'memberNick';
  /** 검색값 */
  searchingVal: string;
}

export interface MemberControllerReadMembersParams {
  /** 회원상태 0:대기,1:휴면,7:승인대기,8:정지,9:탈퇴 */
  memberStatus?: string;
  /** Gender:성별 m:남성, f:여성 */
  memberGender?: string;
  memberAgeMin?: string;
  memberAgeMax?: string;
  /** 검색컬럼명 */
  searchingKey: 'memberIdx' | 'memberId' | 'memberName' | 'memberNick' | 'memberPhone1';
  /** 검색값 */
  searchingVal: string;
  /** sorting 컬럼명 */
  sortBy?: 'memberIdx' | 'memberId' | 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface BoardControllerReadBoardCategoryParams {
  /** boardClassIdx */
  boardClassIdx: number;
}

export interface BoardControllerReadPostsParams {
  postScore?: string;
  adminRole?: string;
  /** 학생페이지 노출여부 1:보임, 0:안보임 */
  isVisible?: string;
  /** 공지일때만 0:메인노출안함, 1:메인노출함 */
  onMain?: string;
  /** 공지일때만 0:메인노출안함, 1:메인노출함 */
  isEvent?: string;
  /** boardClassIdx */
  boardClassIdx?: string;
  /** boardCategoryIdx */
  boardCategoryIdx?: string;
  /** vodProductIdx */
  vodProductIdx?: string;
  /** vodProductContentIdx */
  vodProductContentIdx?: string;
  /** lessonIdx */
  lessonIdx?: string;
  /** 검색컬럼명 */
  searchingDateKey: 'all' | 'createAt';
  /** 검색 시작일(YYYY-MM-DD) */
  searchingStartDate?: string;
  /** 검색 종료일(YYYY-MM-DD) */
  searchingEndDate?: string;
  /** 검색컬럼명 */
  searchingKey:
    | 'all'
    | 'postIdx'
    | 'postTitle'
    | 'adminIdx'
    | 'adminName'
    | 'adminNick'
    | 'adminPhone1'
    | 'memberIdx'
    | 'memberName'
    | 'memberNick'
    | 'memberPhone'
    | 'vodProductTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'postTitle' | 'creatAt' | 'vCount';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadTrafficStatisticsParams {
  /**
   * 검색 년/월
   * @example 2023/2023-02
   */
  searchingDate: string;
}

export interface VodControllerReadTranscodeStatisticsParams {
  /**
   * 검색 년/월
   * @example 2023/2023-02
   */
  searchingDate: string;
}

export interface VodControllerGetTranscodingParams {
  kollus_ufkey: string;
}

export interface VodControllerReadKollusMediaParams {
  /** 삭제 여부 0: false, 1: true */
  isDeleted?: string;
  /** 암호화 업로드 여부 0: false, 1: true */
  is_encryption_upload?: string;
  /** 재생시간필터 */
  vodDurationMin?: number;
  /** 재생시간필터 */
  vodDurationMax?: number;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'original_file_name';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'original_file_name' | 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
  tagTitle?: string;
}

export interface VodControllerReadReferenceParams {
  vodProductCategoryIndexes?: string;
  vodProductSubCategoryIndexes?: string;
  vodProductSubSubCategoryIndexes?: string;
  vodProductSubSubSubCategoryIndexes?: string;
  vodProductSubSubSubSubCategoryIndexes?: string;
  /** gif,jpg,jpeg,png,web,doc,txt,hwp,mwpx,ppt,pptx,xls,xlsx,zip,rar,7z,alz */
  mimetype?: string;
  /** productData: 상품자료, productImage: 상품이미지 */
  fileType?: string;
  /** 연결대상 */
  connectionKey?: 'vodProduct' | 'vodProductChapter' | 'vodProductContent';
  /** 연결대상의 INDEX */
  connectionIdx?: number;
  /** 검색컬럼명 */
  searchingDateKey: 'all' | 'createAt';
  /** 검색 시작일(YYYY-MM-DD) */
  searchingStartDate?: string;
  /** 검색 종료일(YYYY-MM-DD) */
  searchingEndDate?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'fileIdx' | 'fileTitle';
  /** 검색값 */
  searchingVal?: string;
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadLectureNoteParams {
  /** 검색컬럼명 */
  searchingDateKey: 'all' | 'createAt';
  /** 검색 시작일(YYYY-MM-DD) */
  searchingStartDate?: string;
  /** 검색 종료일(YYYY-MM-DD) */
  searchingEndDate?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'lectureNoteIdx' | 'lectureNoteTitle';
  /** 검색값 */
  searchingVal?: string;
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadVodProductCategoryParams {
  /** 상품 카테고리IDX */
  vodProductCategoryIdx?: number;
  /** 상품 서브 카테고리IDX */
  vodProductSubCategoryIdx?: number;
  /** 상품 서브서브 카테고리IDX */
  vodProductSubSubCategoryIdx?: number;
  /** 상품 서브서브서브 카테고리IDX */
  vodProductSubSubSubCategoryIdx?: number;
  /** 상품 서브서브서브서브 카테고리IDX */
  vodProductSubSubSubSubCategoryIdx?: number;
}

export interface VodControllerReadVodProductsParams {
  vodProductCategoryIndexes?: string;
  vodProductSubCategoryIndexes?: string;
  vodProductSubSubCategoryIndexes?: string;
  vodProductSubSubSubCategoryIndexes?: string;
  vodProductSubSubSubSubCategoryIndexes?: string;
  /** adminIndexes */
  adminIndexes?: string;
  /** 검색컬럼명 */
  searchingKey:
    | 'all'
    | 'vodProductTitle'
    | 'vodProductCategoryTitle'
    | 'adminIdx'
    | 'adminId'
    | 'adminName'
    | 'adminNick'
    | 'adminphone';
  /** 검색값 */
  searchingVal?: string;
  /** 0:VOD, 1:LIVE */
  isLive?: string;
  /** 라이브선택시: 요일(0:일~6:토) */
  liveWeek?: string;
  /** 라이브선택시: 회당강의분수 */
  liveDurationMin?: string;
  /** 라이브선택시: 회당강의분수 */
  liveDurationMax?: string;
  /** 그룹수업제한인원: 1: 1:1, 2: 1:2 */
  liveLimitMin?: string;
  /** 그룹수업제한인원: 1: 1:1, 2: 1:2 */
  liveLimitMax?: string;
  /** 라이브선택시: 회차 */
  liveCountMin?: number;
  /** 라이브선택시: 회차 */
  liveCountMax?: number;
  /** 할인필터 0:안함, 1:함 */
  isDiscount?: string;
  /** 쿠폰필터 0:안함, 1:함 */
  isCoupon?: string;
  /** 추천필터 0:안함, 1:함 */
  isRecommend?: string;
  /** 0:VOD, 1:LIVE */
  isMo?: string;
  /** 상태필터 0:활성, 9:비활성 */
  isStatus?: string;
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadVodProductSummaryParams {
  /** vodProductIndexes: 1,2,3,4, */
  vodProductIndexes?: string;
}

export interface VodControllerReadlessonsParams {
  /** 0:VOD, 1:LIVE */
  isLive: string;
  /** 라이브선택시: 요일(0:일~6:토) */
  liveWeek?: string;
  /** 라이브선택시: 회당강의분수 */
  liveDurationMin?: string;
  /** 라이브선택시: 회당강의분수 */
  liveDurationMax?: string;
  /** 그룹수업제한인원: 1: 1:1, 2: 1:2 */
  liveLimitMin?: string;
  /** 그룹수업제한인원: 1: 1:1, 2: 1:2 */
  liveLimitMax?: string;
  /** 라이브선택시: 회차 */
  liveCountMin?: number;
  /** 라이브선택시: 회차 */
  liveCountMax?: number;
  /** 레슨상태 0:수강,8:종강,9:수강취소 */
  lessonStatus: string;
  /** 검색컬럼명 */
  searchingDateKey: 'all' | 'createAt' | 'lessonMemberStartDate' | 'lessonMemberEndDate';
  /** 검색 시작일(YYYY-MM-DD) */
  searchingStartDate?: string;
  /** 검색 종료일(YYYY-MM-DD) */
  searchingEndDate?: string;
  /** 검색컬럼명 */
  searchingKey:
    | 'all'
    | 'memberIdx'
    | 'memberId'
    | 'memberName'
    | 'memberNick'
    | 'memberPhone1'
    | 'vodProductTitle'
    | 'adminIdx'
    | 'adminId'
    | 'adminName'
    | 'adminNick'
    | 'adminPhone1';
  /** 검색값 */
  searchingVal?: string;
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface VodControllerReadChangeSchedulesParams {
  /**
   * 검색 기간(시작일)
   * @example 2023-02-01
   */
  searchingStartDate: string;
  /**
   * 검색 기간(종료일)
   * @example 2023-02-01
   */
  searchingEndDate: string;
  /** scheduleChangeIdx */
  scheduleChangeIdx?: number;
  /** student, teacher */
  isRequester?: string;
  /** 0,1,2 (0 요청 1 승인 2 거부) */
  isConfirm?: string;
  /** 검색컬럼명 */
  searchingKey:
    | 'memberIdx'
    | 'memberId'
    | 'memberName'
    | 'memberNick'
    | 'memberPhone1'
    | 'vodProductTitle';
  /** 검색값 */
  searchingVal: string;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadScheduleParams {
  /** adminIndexes */
  adminIndexes?: string;
  /** memberIndexes */
  memberIndexes?: string;
  /** lessonIndexes */
  lessonIndexes?: string;
  /**
   * 검색 기간(시작일)
   * @example 2023-02-01
   */
  searchingStartDate: string;
  /**
   * 검색 기간(종료일)
   * @example 2023-02-01
   */
  searchingEndtDate: string;
}

export interface TagControllerReadTagParams {
  tagTitle?: string;
}

export interface CouponControllerReadCouponGroupsParams {
  /** 검색컬럼명 */
  searchingKey: 'all' | 'couponName' | 'memberName' | 'memberNick';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'couponExpiredDate' | 'createAt' | 'couponName' | 'memberName' | 'memberNick';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface CouponControllerReadCouponGroupParams {
  /** sorting 컬럼명 */
  sortBy?: 'couponUsedDate' | 'memberName' | 'memberNick';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
  couponGroupCode: string;
}

export interface PaymentControllerReadPaymentsParams {
  lessonIdx?: number;
  memberIdx?: number;
  /** 0:VOD, 1:LIVE */
  isLive?: string;
  /** 0:안됨, 1:됨 */
  isDiscount?: string;
  /** 결제상태 0:결제창호출,1:결제완료,7:취소요청,8:부분취소,9:취소 */
  isPaid?: string;
  /** 0:국내, 1:해외 */
  isGlobal?: string;
  /** 검색컬럼명 */
  searchingDateKey: 'all' | 'paidDate' | 'refundedDate';
  /** 검색 시작일(YYYY-MM-DD) */
  searchingStartDate?: string;
  /** 검색 종료일(YYYY-MM-DD) */
  searchingEndDate?: string;
  /** 검색컬럼명 */
  searchingKey?:
    | 'all'
    | 'paymentIdx'
    | 'vodproductTitle'
    | 'memberId'
    | 'memberName'
    | 'memberNick'
    | 'memberPhone1';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'memberId' | 'memberName' | 'memberNick' | 'memberPhone1';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface TemplateControllerReadPostParams {
  URL: string;
}

export interface TemplateControllerAdminSigninParams {
  URL: string;
  templateIndexes?: string;
}

export interface QuizControllerReadQuestionParams {
  /** 문제타입 필터 */
  questionType?: string;
  /** 점수 필터 Min */
  questionPointMin?: string;
  /** 점수 필터 Max */
  questionPointMax?: string;
  /** 난이도 필터 Min */
  questionLevelMin?: string;
  /** 난이도 필터 Max */
  questionLevelMax?: string;
  /** 검색컬럼명 */
  searchingDateKey: 'all' | 'createAt';
  /** 검색 시작일(YYYY-MM-DD) */
  searchingStartDate?: string;
  /** 검색 종료일(YYYY-MM-DD) */
  searchingEndDate?: string;
  /** 검색컬럼명 */
  searchingKey?: 'questionIdx' | 'quizTitle';
  /** 검색값 */
  searchingVal?: string;
  vodProductCategoryIndexes?: string;
  vodProductSubCategoryIndexes?: string;
  vodProductSubSubCategoryIndexes?: string;
  vodProductSubSubSubCategoryIndexes?: string;
  vodProductSubSubSubSubCategoryIndexes?: string;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
  tagTitle?: string;
}

export interface QuizControllerReadQuizParams {
  /** 총점 필터 Min */
  totalPointMin?: string;
  /** 총점 필터 Max */
  totalPointMax?: string;
  /** 문제수 필터 Min */
  questionCntMin?: string;
  /** 문제수 필터 Max */
  questionCntMax?: string;
  /** 검색컬럼명 */
  searchingDateKey: 'all' | 'createAt';
  /** 검색 시작일(YYYY-MM-DD) */
  searchingStartDate?: string;
  /** 검색 종료일(YYYY-MM-DD) */
  searchingEndDate?: string;
  vodProductCategoryIndexes?: string;
  vodProductSubCategoryIndexes?: string;
  vodProductSubSubCategoryIndexes?: string;
  vodProductSubSubSubCategoryIndexes?: string;
  vodProductSubSubSubSubCategoryIndexes?: string;
  /** 검색컬럼명 */
  searchingKey?: 'quizIdx' | 'quizTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
  tagTitle?: string;
}

export interface QuizControllerReadQuizHistoryParams {
  quizHistoryGroup?: string;
  quizTags?: string;
}

export interface WpMemberControllerWpReadCouponParams {
  /** 쿠폰상태(초기값은 not use) */
  status: 'all' | 'not use' | 'use' | 'expired';
}

export interface WpMemberControllerWpReadEventParams {
  /** evnet idx */
  eventIdx?: number;
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpVodControllerReadlessonsParams {
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodProductTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'createAt' | 'updateAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpVodControllerReadlessonParams {
  /** 1: 결과보기 */
  getResult?: number;
  lessonIdx: string;
}

export interface WpVodControllerGetUrlParams {
  lessonIdx: number;
  vodProductContentIdx: number;
}

export interface WpVodControllerGetUrl2Params {
  lessonIdx: number;
  vodProductContentIdx: number;
  vodHistoryFileIdx: number;
}

export interface WpVodControllerReadVodProductCategoryParams {
  URL: string;
  /** 상품 카테고리IDX */
  vodProductCategoryIdx?: number;
  /** 상품 서브 카테고리IDX */
  vodProductSubCategoryIdx?: number;
  /** 상품 서브서브 카테고리IDX */
  vodProductSubSubCategoryIdx?: number;
  /** 상품 서브서브서브 카테고리IDX */
  vodProductSubSubSubCategoryIdx?: number;
  /** 상품 서브서브서브서브 카테고리IDX */
  vodProductSubSubSubSubCategoryIdx?: number;
}

export interface WpVodControllerReadWpVodProductsParams {
  URL: string;
  /** 강사 index */
  adminIdx?: number;
  /** 찜여부 */
  memberIdx?: number;
  /** 0:VOD, 1:LIVE */
  isLive?: string;
  /** 0:noen, 1:trial */
  isLiveTrial?: string;
  /** 라이브선택시: 요일(0:일~6:토) */
  liveWeek?: string;
  /** 라이브선택시: 회당강의분수 */
  liveDurationMin?: string;
  /** 라이브선택시: 회당강의분수 */
  liveDurationMax?: string;
  /** 그룹수업제한인원: 1: 1:1, 2: 1:2 */
  liveLimitMin?: string;
  /** 그룹수업제한인원: 1: 1:1, 2: 1:2 */
  liveLimitMax?: string;
  /** 라이브선택시: 회차 */
  liveCountMin?: number;
  /** 라이브선택시: 회차 */
  liveCountMax?: number;
  /** 할인필터 0:안함, 1:함 */
  isDiscount?: string;
  /** 쿠폰필터 0:안함, 1:함 */
  isCoupon?: string;
  /** 추천필터 0:안함, 1:함 */
  isRecommend?: string;
  /** MO필터 0:아님, 1:맞음 */
  isMo?: string;
  vodProductCategoryIndexes?: string;
  vodProductSubCategoryIndexes?: string;
  vodProductSubSubCategoryIndexes?: string;
  vodProductSubSubSubCategoryIndexes?: string;
  vodProductSubSubSubSubCategoryIndexes?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodProductTitle' | 'vodProductCategoryTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'createAt' | 'popularity';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpVodControllerReadWpVodProductParams {
  URL: string;
  /** 찜여부 */
  memberIdx?: number;
  vodProductIdx: number;
}

export interface WpVodControllerReadVodProductSummaryParams {
  URL: string;
  /** vodProductIndexes: 1,2,3,4, */
  vodProductIndexes?: string;
}

export interface WpVodControllerReadAdminsParams {
  URL: string;
  /** Gender:성별 m:남성, f:여성 */
  adminGender?: string;
  adminAgeMin?: string;
  adminAgeMax?: string;
  /** 강사 IDX */
  adminIdx?: number;
  /** sorting 컬럼명 */
  sortBy?: 'adminIdx' | 'adminId' | 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpVodControllerReadChangeSchedulesParams {
  /**
   * 검색 기간(시작일)
   * @example 2023-02-01
   */
  searchingStartDate: string;
  /**
   * 검색 기간(종료일)
   * @example 2023-02-01
   */
  searchingEndDate: string;
  /** scheduleChangeIdx */
  scheduleChangeIdx?: number;
  /** student, teacher */
  isRequester?: string;
  /** 0,1,2 (0 요청 1 승인 2 거부) */
  isConfirm?: string;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpVodControllerReadAdminAssessParams {
  URL: string;
  /** 특정 강사 조회 */
  adminIdx?: number;
  /** 특정 학생 것만 조회 */
  memberIdx?: number;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpPaymentControllerReadPaymentParams {
  paymentIdx?: number;
  /**
   * 검색 기간(시작일)
   * @example 2023-02-01
   */
  searchingStartDate?: string;
  /**
   * 검색 기간(종료일)
   * @example 2023-02-01
   */
  searchingEndDate?: string;
  /** 검색컬럼명 */
  searchingKey?: 'all' | 'vodProductTitle';
  /** 검색값 */
  searchingVal?: string;
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpBoardControllerReadWpBoardClassParams {
  URL: string;
  /** 유일생성 게시판에 대한 정의 notice:공지사항, faq: 자주묻는질문, qna: 문의, review: 리뷰 */
  uniqBoard?: 'notice' | 'faq' | 'qna' | 'review';
}

export interface WpBoardControllerReadWpBoardCategoryParams {
  URL: string;
  /** boardClassIdx */
  boardClassIdx: number;
}

export interface WpBoardControllerReadWpPostsParams {
  URL: string;
  /** 공지일때만 0:메인노출안함, 1:메인노출함 */
  onMain: string;
  /** 공지일때만 0:메인노출안함, 1:메인노출함 */
  isEvent: string;
  /** boardClassIdx */
  boardClassIdx?: string;
  /** 유일생성 게시판에 대한 정의 notice:공지사항, faq: 자주묻는질문, qna: 문의, review: 리뷰 */
  uniqBoard?: 'notice' | 'faq' | 'qna' | 'review';
  /** boardCategoryIdx */
  boardCategoryIdx?: string;
  /** vodProductIdx */
  vodProductIdx?: string;
  /** vodProductContentIdx */
  vodProductContentIdx?: string;
  /** 답변여부: 0:안달림, 1:달림 */
  isCommented?: string;
  /** 검색컬럼명 */
  searchingDateKey: 'all' | 'createAt';
  /** 검색 시작일(YYYY-MM-DD) */
  searchingStartDate?: string;
  /** 검색 종료일(YYYY-MM-DD) */
  searchingEndDate?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'postTitle' | 'writerName';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'postTitle' | 'creatAt' | 'vCount';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpBoardControllerReadWpMyPostsParams {
  /** 1: 내것만 보임 */
  readMine?: string;
  /** 공지일때만 0:메인노출안함, 1:메인노출함 */
  onMain: string;
  /** 공지일때만 0:메인노출안함, 1:메인노출함 */
  isEvent: string;
  /** boardClassIdx */
  boardClassIdx?: string;
  /** 유일생성 게시판에 대한 정의 notice:공지사항, faq: 자주묻는질문, qna: 문의, review: 리뷰 */
  uniqBoard?: 'notice' | 'faq' | 'qna' | 'review';
  /** boardCategoryIdx */
  boardCategoryIdx?: string;
  /** vodProductIdx */
  vodProductIdx?: string;
  /** vodProductContentIdx */
  vodProductContentIdx?: string;
  /** vodProductIdx */
  lessonIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'postTitle';
  /** 검색값 */
  searchingVal?: string;
  /** 답변여부: 0:안달림, 1:달림 */
  isCommented?: string;
  /** sorting 컬럼명 */
  sortBy?: 'postTitle' | 'creatAt' | 'vCount';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpBoardControllerReadWpMyPosts2Params {
  /** 1: 내것만 보임 */
  readMine?: string;
  /** 공지일때만 0:메인노출안함, 1:메인노출함 */
  onMain: string;
  /** 공지일때만 0:메인노출안함, 1:메인노출함 */
  isEvent: string;
  /** boardClassIdx */
  boardClassIdx?: string;
  /** 유일생성 게시판에 대한 정의 notice:공지사항, faq: 자주묻는질문, qna: 문의, review: 리뷰 */
  uniqBoard?: 'notice' | 'faq' | 'qna' | 'review';
  /** boardCategoryIdx */
  boardCategoryIdx?: string;
  /** vodProductIdx */
  vodProductIdx?: string;
  /** vodProductContentIdx */
  vodProductContentIdx?: string;
  /** vodProductIdx */
  lessonIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'postTitle';
  /** 검색값 */
  searchingVal?: string;
  /** 답변여부: 0:안달림, 1:달림 */
  isCommented?: string;
  /** sorting 컬럼명 */
  sortBy?: 'postTitle' | 'creatAt' | 'vCount';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpBoardControllerReadWpMyPostParams {
  URL: string;
  memberIdx?: number;
  postIdx: string;
}

export interface WpQuizControllerReadWpQuizParams {
  /** 상품에 포함된 Quiz는 스케쥴IDX도 보낼것 */
  scheduleIdx?: string;
  quizIdx?: string;
}

export interface WpQuizControllerPlatomLevelTestParams {
  URL: string;
  /** vodProductIdx */
  vodProductIdx: number;
}

export interface WpQuizControllerReadWpQuizHistoryParams {
  quizHistoryGroup?: string;
}

export interface WpQuizControllerUpdateWpQuizHistoryParams {
  inputAns?: string;
  quizHistoryIdx: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Education-Platform-API
 * @version 0.0.1
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  vod = {
    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerInsertQuestionCategoryL1
     * @summary 기본테이블생성
     * @request POST:/vod/api/system/default/board
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerInsertQuestionCategoryL1: (
      data: CreateInitailBoardDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/default/board`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerReadTrafficStatistics
     * @summary 트래픽통계
     * @request GET:/vod/api/system/statistics/traffic
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerReadTrafficStatistics: (
      query: SystemControllerReadTrafficStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/traffic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsTodayMember
     * @summary [대시보드] 오늘의 가입자수
     * @request GET:/vod/api/system/statistics/today/member
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsTodayMember: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/today/member`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsTodayPayment
     * @summary [대시보드] 오늘의 수익
     * @request GET:/vod/api/system/statistics/today/payment
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsTodayPayment: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/today/payment`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsTodayLive
     * @summary [대시보드] 오늘의 수업 진행 시간
     * @request GET:/vod/api/system/statistics/today/live
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsTodayLive: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/today/live`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsTodayVod
     * @summary [대시보드] 오늘의 수업 시청 시간
     * @request GET:/vod/api/system/statistics/today/vod
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsTodayVod: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/today/vod`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsMonthMember
     * @summary [대시보드] 월별 가입자수
     * @request GET:/vod/api/system/statistics/month/member
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsMonthMember: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/month/member`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsMonthPayment
     * @summary [대시보드] 월별 수익
     * @request GET:/vod/api/system/statistics/month/payment
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsMonthPayment: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/month/payment`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsMonthLive
     * @summary [대시보드] 월별 수업 진행 시간(라이브)
     * @request GET:/vod/api/system/statistics/month/live
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsMonthLive: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/month/live`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsMonthVod
     * @summary [대시보드] 월별 수업 진행 시간(vod)
     * @request GET:/vod/api/system/statistics/month/vod
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsMonthVod: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/month/vod`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsHistoryPayment
     * @summary [대시보드] 구매횟수 VOD/LIVE
     * @request GET:/vod/api/system/statistics/history/payment
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsHistoryPayment: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/history/payment`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsHistoryMember
     * @summary [대시보드] 가입자수/탈퇴자수
     * @request GET:/vod/api/system/statistics/history/member
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsHistoryMember: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/history/member`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerStatisticsHistoryLive
     * @summary [대시보드] 라이브 진행 상황
     * @request GET:/vod/api/system/statistics/history/live
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerStatisticsHistoryLive: (
      query: SystemControllerStatisticsHistoryLiveParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/history/live`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerOverCron
     * @summary traffic, space notification cron (매일 9시)
     * @request GET:/vod/api/system/cron/notifications
     * @response `200` `void`
     */
    systemControllerOverCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/notifications`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerExpiredCron
     * @summary expired date cron (매일 1시)
     * @request GET:/vod/api/system/cron/expired
     * @response `200` `void`
     */
    systemControllerExpiredCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/expired`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerLiveStatus
     * @summary 스케쥴 상태 변경 (매 5분)
     * @request GET:/vod/api/system/cron/liveStatus
     * @response `200` `void`
     */
    systemControllerLiveStatus: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/liveStatus`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerPausedCron
     * @summary 휴면전환 (매일 10시)
     * @request GET:/vod/api/system/cron/paused
     * @response `200` `void`
     */
    systemControllerPausedCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/paused`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerBeforeLiveStart
     * @summary 라이브시작전알림(매 5분)
     * @request GET:/vod/api/system/cron/beforeLiveStart
     * @response `200` `void`
     */
    systemControllerBeforeLiveStart: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/beforeLiveStart`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerAutoLiveStart
     * @summary 15분전 라이브 자동 시작
     * @request GET:/vod/api/system/cron/autoLiveStart
     * @response `200` `void`
     */
    systemControllerAutoLiveStart: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/autoLiveStart`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags xApi
     * @name XApiControllerStatement
     * @request POST:/vod/api/xapi
     * @response `201` `void`
     */
    xApiControllerStatement: (data: StatementDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/xapi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerDefault
     * @summary [공통] 기본정보획득
     * @request POST:/vod/api/user/default
     * @response `200` `SigninResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerDefault: (data: DefaultDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, BaseResponseDto>({
        path: `/vod/api/user/default`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerSignPhotoFileUpload
     * @summary 사진 업로드
     * @request POST:/vod/api/user/photo/upload
     * @response `201` `SignFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerSignPhotoFileUpload: (data: SignPhotoFileUploadDto, params: RequestParams = {}) =>
      this.request<SignFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/user/photo/upload`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerDuplicatedAdmin
     * @summary [관리자] 중복검사
     * @request GET:/vod/api/user/admin/duplicated
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerDuplicatedAdmin: (
      query: SignControllerDuplicatedAdminParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/admin/duplicated`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerAdminSignup
     * @summary [관리자] 가입
     * @request POST:/vod/api/user/admin/signup
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerAdminSignup: (data: AdminSignupDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/admin/signup`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerAdminSignin
     * @summary [관리자용] 로그인
     * @request POST:/vod/api/user/admin/signin
     * @response `200` `SigninResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerAdminSignin: (data: AdminSigninByEmailDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, BaseResponseDto>({
        path: `/vod/api/user/admin/signin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerMemberSignPhotoFileUpload
     * @summary [회원용] 사진 업로드
     * @request POST:/vod/api/user/member/photo/upload
     * @response `201` `SignFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerMemberSignPhotoFileUpload: (
      data: SignPhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<SignFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/user/member/photo/upload`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerDuplicatedMember
     * @summary [회원용] 중복검사
     * @request GET:/vod/api/user/duplicated
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerDuplicatedMember: (
      query: SignControllerDuplicatedMemberParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/duplicated`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerMemberVerifiedMail
     * @summary [회원용] 이메일인증코드 발송
     * @request POST:/vod/api/user/member/verifiedMail
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerMemberVerifiedMail: (data: MemberVerifiedMailDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/member/verifiedMail`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerMemberChangePasswordMail
     * @summary [회원용] 비밀번호 변경코드 발송
     * @request POST:/vod/api/user/member/changePasswordMail
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerMemberChangePasswordMail: (
      data: MemberVerifiedMailDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/member/changePasswordMail`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerMemberConfirmPasswordMail
     * @summary [회원용] 비밀번호 변경코드 확인
     * @request POST:/vod/api/user/member/confirmPasswordMail
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerMemberConfirmPasswordMail: (
      data: MemberConfirmPasswordDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/member/confirmPasswordMail`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerMemberChangePassword
     * @summary [회원용] 비밀번호 변경
     * @request POST:/vod/api/user/member/changePassword
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerMemberChangePassword: (
      data: MemberChangePasswordDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/member/changePassword`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerMemberVerifiedPhone
     * @summary [회원용] 휴대폰인증코드 발송
     * @request POST:/vod/api/user/member/verifiedPhone
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerMemberVerifiedPhone: (data: MemberVerifiedPhoneDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/member/verifiedPhone`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerMemberSignup
     * @summary [회원용] 가입
     * @request POST:/vod/api/user/member/signup
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerMemberSignup: (data: MemberSignupDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/member/signup`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerMemberSignin
     * @summary [회원용] 로그인
     * @request POST:/vod/api/user/member/signin
     * @response `200` `SigninResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerMemberSignin: (data: MemberSigninByEmailDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, BaseResponseDto>({
        path: `/vod/api/user/member/signin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerMemberSigninByKaKao
     * @summary [회원용] 카카오 로그인
     * @request POST:/vod/api/user/member/signin/kakao
     * @response `200` `SigninResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerMemberSigninByKaKao: (data: MemberSigninByKaKaoDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, BaseResponseDto>({
        path: `/vod/api/user/member/signin/kakao`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerMemberSigninByNaver
     * @summary [회원용] 네이버 로그인
     * @request POST:/vod/api/user/member/signin/naver
     * @response `200` `SigninResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerMemberSigninByNaver: (data: MemberSigninByNaverDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, BaseResponseDto>({
        path: `/vod/api/user/member/signin/naver`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerCollaborationMail
     * @summary 제휴/협력 문의
     * @request POST:/vod/api/user/collaboration
     * @response `200` `SigninResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerCollaborationMail: (data: CollaborationMailDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, BaseResponseDto>({
        path: `/vod/api/user/collaboration`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerPing
     * @summary PING1
     * @request POST:/vod/api/user/ping1
     * @response `200` `SigninResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerPing: (params: RequestParams = {}) =>
      this.request<SigninResponseDto, BaseResponseDto>({
        path: `/vod/api/user/ping1`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sign
     * @name SignControllerPing2
     * @summary PING1
     * @request POST:/vod/api/user/ping2
     * @response `200` `SigninResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    signControllerPing2: (params: RequestParams = {}) =>
      this.request<SigninResponseDto, BaseResponseDto>({
        path: `/vod/api/user/ping2`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name MessageControllerUpdateAccesskey
     * @summary 센딧 AccessKey 등록
     * @request PUT:/vod/api/message/accesskey
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerUpdateAccesskey: (data: UpdateAccesskeyDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/accesskey`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name MessageControllerReadProfile
     * @summary 센딧 승인된 발신번호/발신프로필 가져오기
     * @request GET:/vod/api/message/profile
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerReadProfile: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/profile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name MessageControllerUpdateProfile
     * @summary 시스템에서 사용할 발신번호/발신프로필 등록
     * @request PUT:/vod/api/message/profile
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerUpdateProfile: (data: UpdateProfileDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/profile`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name MessageControllerReadMessage
     * @summary 메시지 발신기록
     * @request GET:/vod/api/message
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerReadMessage: (
      query: MessageControllerReadMessageParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name MessageControllerSendMessage
     * @summary 메시지 발송
     * @request POST:/vod/api/message/send
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerSendMessage: (data: SendMessageDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/send`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name MessageControllerCreateAlimtalkTemplate
     * @summary 센딧 알림톡 템플릿 등록하기
     * @request POST:/vod/api/message/alimtalk/template
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerCreateAlimtalkTemplate: (
      data: CreateAlimtalkTemplateDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/alimtalk/template`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name MessageControllerReadAlimtalkTemplate
     * @summary 센딧 알림톡 템플릿 가져오기
     * @request GET:/vod/api/message/alimtalk/template
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerReadAlimtalkTemplate: (
      query: MessageControllerReadAlimtalkTemplateParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/alimtalk/template`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name MessageControllerSaveMessage
     * @summary 메시지 수정/저장
     * @request POST:/vod/api/message/save
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerSaveMessage: (data: SaveMessageDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/save`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name MessageControllerSavedMessage
     * @summary 저장된 메시지 조회
     * @request GET:/vod/api/message/save
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerSavedMessage: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/save`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminControllerReadMyInfo
     * @summary 내정보
     * @request GET:/vod/api/admin/myinfo
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    adminControllerReadMyInfo: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/admin/myinfo`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminControllerAdminPhotoFileUpload
     * @summary 사진 업로드
     * @request POST:/vod/api/admin/photo/upload
     * @secure
     * @response `201` `AdminFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    adminControllerAdminPhotoFileUpload: (
      data: AdminPhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<AdminFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/admin/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminControllerReadAdminAssess
     * @summary 회원이 강사를 평가한 것 조회
     * @request GET:/vod/api/admin/assess/admin
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    adminControllerReadAdminAssess: (
      query: AdminControllerReadAdminAssessParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/admin/assess/admin`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminControllerCreateTeacherBreaktime
     * @summary 관리자 휴게시간 생성
     * @request POST:/vod/api/admin/breaktime
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    adminControllerCreateTeacherBreaktime: (
      data: CreateAdminBreaktimeDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/admin/breaktime`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminControllerReadTeacherBreaktime
     * @summary 관리자 휴게시간 조회
     * @request GET:/vod/api/admin/breaktime
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    adminControllerReadTeacherBreaktime: (
      query: AdminControllerReadTeacherBreaktimeParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/admin/breaktime`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminControllerReadAdmins
     * @summary 관리자들 조회
     * @request GET:/vod/api/admin
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    adminControllerReadAdmins: (
      query: AdminControllerReadAdminsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/admin`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminControllerCreateAdmin
     * @summary 관리자 생성
     * @request POST:/vod/api/admin
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    adminControllerCreateAdmin: (data: CreateAdminDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/admin`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminControllerUpdateAdminStatus
     * @summary 관리자 상태 일괄변경
     * @request PUT:/vod/api/admin
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    adminControllerUpdateAdminStatus: (data: UpdateAdminStatusDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/admin`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminControllerDeleteAdmin
     * @summary 관리자 삭제
     * @request DELETE:/vod/api/admin
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    adminControllerDeleteAdmin: (data: DeleteAdminDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/admin`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminControllerUpdateAdmin
     * @summary 관리자 수정
     * @request PUT:/vod/api/admin/{adminIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    adminControllerUpdateAdmin: (
      adminIdx: string,
      data: UpdateAdminDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/admin/${adminIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberControllerMemberPhotoFileUpload
     * @summary 사진 업로드
     * @request POST:/vod/api/member/photo/upload
     * @secure
     * @response `201` `MemberFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerMemberPhotoFileUpload: (
      data: MemberPhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<MemberFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/member/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberControllerCreateMemberAssess
     * @summary 회원 평가 생성
     * @request POST:/vod/api/member/assess/member
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerCreateMemberAssess: (data: CreateMemberAssessDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/member/assess/member`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberControllerReadMemberAssess
     * @summary 강사가 회원을 평가한 것
     * @request GET:/vod/api/member/assess/member
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerReadMemberAssess: (
      query: MemberControllerReadMemberAssessParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/member/assess/member`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberControllerUpdateMemberAssess
     * @summary 회원 평가 수정
     * @request PUT:/vod/api/member/assess/member/{memberAssessIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerUpdateMemberAssess: (
      memberAssessIdx: number,
      data: UpdateMemberAssessDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/member/assess/member/${memberAssessIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberControllerDuplicatedMember
     * @summary 중복검사
     * @request GET:/vod/api/member/duplicated
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerDuplicatedMember: (
      query: MemberControllerDuplicatedMemberParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/member/duplicated`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberControllerReadMembers
     * @summary 회원들 조회
     * @request GET:/vod/api/member
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerReadMembers: (
      query: MemberControllerReadMembersParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/member`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberControllerCreateMember
     * @summary 회원 생성
     * @request POST:/vod/api/member
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerCreateMember: (data: CreateMemberDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/member`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberControllerUpdateMemberStatus
     * @summary 회원 상태 일괄변경
     * @request PUT:/vod/api/member
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerUpdateMemberStatus: (data: UpdateMemberStatusDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/member`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberControllerDeleteMember
     * @summary 회원 삭제
     * @request DELETE:/vod/api/member
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerDeleteMember: (data: DeleteMemberDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/member`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberControllerUpdateMember
     * @summary 회원 수정
     * @request PUT:/vod/api/member/{memberIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerUpdateMember: (
      memberIdx: string,
      data: UpdateMemberDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/member/${memberIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerBoardFileUpload
     * @summary  게시판 사진 업로드
     * @request POST:/vod/api/board/file/upload
     * @secure
     * @response `201` `BoardFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerBoardFileUpload: (data: BoardFileUploadDto, params: RequestParams = {}) =>
      this.request<BoardFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/board/file/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerCreateBoardClass
     * @summary 게시판 생성
     * @request POST:/vod/api/board/class
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerCreateBoardClass: (data: CreateBoardClassDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/class`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerReadBoardClass
     * @summary 게시판 조회
     * @request GET:/vod/api/board/class
     * @secure
     * @response `200` `BoardClassResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerReadBoardClass: (params: RequestParams = {}) =>
      this.request<BoardClassResponseDto, BaseResponseDto>({
        path: `/vod/api/board/class`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerUpdateBoardClass
     * @summary 게시판 수정(게시판 숨김여부 포함)
     * @request PUT:/vod/api/board/class/{boardClassIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerUpdateBoardClass: (
      boardClassIdx: number,
      data: UpdateBoardClassDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/class/${boardClassIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerDeleteBoardClass
     * @summary 게시판 삭제
     * @request DELETE:/vod/api/board/class/{boardClassIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerDeleteBoardClass: (boardClassIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/class/${boardClassIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerCreateBoardCategory
     * @summary 게시글 카테고리 생성
     * @request POST:/vod/api/board/category
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerCreateBoardCategory: (
      data: CreateBaordCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/category`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerReadBoardCategory
     * @summary 게시글 카테고리 조회
     * @request GET:/vod/api/board/category
     * @secure
     * @response `200` `BoardCategoryResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerReadBoardCategory: (
      query: BoardControllerReadBoardCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BoardCategoryResponseDto, BaseResponseDto>({
        path: `/vod/api/board/category`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerUpdateBoardCategory
     * @summary 게시글 카테고리 수정
     * @request PUT:/vod/api/board/category/{boardCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerUpdateBoardCategory: (
      boardCategoryIdx: number,
      data: UpdateBaordCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/category/${boardCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerDeleteBoardCategory
     * @summary 게시글 카테고리 삭제
     * @request DELETE:/vod/api/board/category/{boardCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerDeleteBoardCategory: (boardCategoryIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/category/${boardCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerReadPosts
     * @summary 게시글들 조회
     * @request GET:/vod/api/board/post
     * @secure
     * @response `200` `PostResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerReadPosts: (query: BoardControllerReadPostsParams, params: RequestParams = {}) =>
      this.request<PostResponseDto, BaseResponseDto>({
        path: `/vod/api/board/post`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerCreatePost
     * @summary 게시글 생성
     * @request POST:/vod/api/board/post
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerCreatePost: (data: CreatePostDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerReadPost
     * @summary 게시글 조회
     * @request GET:/vod/api/board/post/{postIdx}
     * @secure
     * @response `200` `PostResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerReadPost: (postIdx: string, params: RequestParams = {}) =>
      this.request<PostResponseDto, BaseResponseDto>({
        path: `/vod/api/board/post/${postIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerUpdatePost
     * @summary 게시글 수정
     * @request PUT:/vod/api/board/post/{postIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerUpdatePost: (postIdx: string, data: UpdatePostDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/post/${postIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerDeletePost
     * @summary 게시글 삭제
     * @request DELETE:/vod/api/board/post/{postIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerDeletePost: (postIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/post/${postIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerCreateComment
     * @summary 댓글 생성
     * @request POST:/vod/api/board/comment
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerCreateComment: (data: CreateCommentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/comment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerUpdateComment
     * @summary 댓글 수정
     * @request PUT:/vod/api/board/commnet/{commentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerUpdateComment: (
      commentIdx: string,
      data: UpdateCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/commnet/${commentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerDeleteComment
     * @summary 댓글 삭제
     * @request DELETE:/vod/api/board/commnet/{commentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerDeleteComment: (commentIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/commnet/${commentIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerCreateCocomment
     * @summary 대댓글 생성
     * @request POST:/vod/api/board/cocomment
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerCreateCocomment: (data: CreateCocommentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/cocomment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerUpdateCocomment
     * @summary 대댓글 수정
     * @request PUT:/vod/api/board/cocommnet/{cocommentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerUpdateCocomment: (
      cocommentIdx: string,
      data: UpdateCocommentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/cocommnet/${cocommentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Board
     * @name BoardControllerDeleteCocomment
     * @summary 대댓글 삭제
     * @request DELETE:/vod/api/board/cocommnet/{cocommentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerDeleteCocomment: (cocommentIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/cocommnet/${cocommentIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerBbbCallback
     * @request POST:/vod/api/vod/callback/bbb
     * @response `201` `void`
     */
    vodControllerBbbCallback: (data: BbbCallbackDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/vod/callback/bbb`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerKollusCallbackUploaded
     * @request POST:/vod/api/vod/callback/uploaded
     * @response `201` `void`
     */
    vodControllerKollusCallbackUploaded: (
      data: KollusCallbackUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/vod/api/vod/callback/uploaded`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerKollusCallbackTranscoded
     * @request POST:/vod/api/vod/callback/transcoded
     * @response `201` `void`
     */
    vodControllerKollusCallbackTranscoded: (
      data: KollusCallbackUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/vod/api/vod/callback/transcoded`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerKollusCallback
     * @request POST:/vod/api/vod/callback
     * @response `201` `void`
     */
    vodControllerKollusCallback: (data: KollusCallbackDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/vod/callback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadTrafficStatistics
     * @summary VOD 트래픽 통계
     * @request GET:/vod/api/vod/kollus/statistics/traffic
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadTrafficStatistics: (
      query: VodControllerReadTrafficStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/kollus/statistics/traffic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadTranscodeStatistics
     * @summary VOD 트랜스코딩 통계
     * @request GET:/vod/api/vod/kollus/statistics/transcoded
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadTranscodeStatistics: (
      query: VodControllerReadTranscodeStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/kollus/statistics/transcoded`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerGetUploadUrl
     * @summary VOD 업로드 URL 획득
     * @request POST:/vod/api/vod/kollus
     * @secure
     * @response `200` `VodUploadUrlResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetUploadUrl: (data: GetUploadUrlDto, params: RequestParams = {}) =>
      this.request<VodUploadUrlResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/kollus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerGetTranscoding
     * @summary VOD 트랜스코딩상태확인
     * @request GET:/vod/api/vod/kollus/transcoding
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetTranscoding: (
      query: VodControllerGetTranscodingParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/kollus/transcoding`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadKollusMedia
     * @summary VOD 컨텐츠 목록 조회
     * @request GET:/vod/api/vod/kollus/media
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadKollusMedia: (
      query: VodControllerReadKollusMediaParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/kollus/media`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateKollusMedia
     * @summary VOD 컨텐츠 목록 수정
     * @request POST:/vod/api/vod/kollus/media
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateKollusMedia: (data: UpdateKollusMediaDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/kollus/media`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteKollusMedia
     * @summary VOD 컨텐츠 목록 삭제
     * @request PUT:/vod/api/vod/kollus/media
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteKollusMedia: (data: DeleteKollusMediaDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/kollus/media`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerMediaPlayingUrl
     * @summary 미디어 재생 URL 획득
     * @request GET:/vod/api/vod/kollus/media/{ufkey}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerMediaPlayingUrl: (ufkey: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/kollus/media/${ufkey}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCategoryFileUpload
     * @summary VOD 연관파일 업로드
     * @request POST:/vod/api/vod/photo/upload
     * @secure
     * @response `201` `VodFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCategoryFileUpload: (data: VodFileUploadDto, params: RequestParams = {}) =>
      this.request<VodFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateReference
     * @summary 강의자료실 파일추가
     * @request POST:/vod/api/vod/reference
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateReference: (data: CreateReferenceDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadReference
     * @summary 강의자료실 조회
     * @request GET:/vod/api/vod/reference
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadReference: (
      query: VodControllerReadReferenceParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteReference
     * @summary 강의자료실 삭제
     * @request DELETE:/vod/api/vod/reference
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteReference: (data: DeleteReferenceDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateReferenceConnection
     * @summary 강의자료 연결일괄해제 후 연결
     * @request PUT:/vod/api/vod/reference/connection
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateReferenceConnection: (
      data: UpdateReferenceConnectionDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference/connection`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateReference
     * @summary 강의자료실 수정
     * @request PUT:/vod/api/vod/reference/{fileIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateReference: (
      fileIdx: number,
      data: CreateReferenceDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference/${fileIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadLectureNote
     * @summary 강의노트 조회
     * @request GET:/vod/api/vod/lectureNote
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadLectureNote: (
      query: VodControllerReadLectureNoteParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lectureNote`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateLectureNote
     * @summary 강의노트 추가
     * @request POST:/vod/api/vod/lectureNote
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateLectureNote: (data: CreateLectureNoteDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lectureNote`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteLectureNote
     * @summary 강의노트 삭제
     * @request DELETE:/vod/api/vod/lectureNote
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteLectureNote: (data: DeleteLectureNoteDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lectureNote`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateLectureNote
     * @summary 강의노트 수정
     * @request PUT:/vod/api/vod/lectureNote/{lectureNoteIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateLectureNote: (
      lectureNoteIdx: number,
      data: CreateLectureNoteDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lectureNote/${lectureNoteIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateVodProductCategory
     * @summary 상품 카테고리 생성
     * @request POST:/vod/api/vod/product/category
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodProductCategory: (
      data: CreateVodProductCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/category`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadVodProductCategory
     * @summary 상품 카테고리 조회
     * @request GET:/vod/api/vod/product/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodProductCategory: (
      query: VodControllerReadVodProductCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/category`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateVodProductCategory
     * @summary 상품 카테고리 수정
     * @request PUT:/vod/api/vod/product/category/{vodProductCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodProductCategory: (
      vodProductCategoryIdx: number,
      data: CreateVodProductCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/category/${vodProductCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteVodProductCategory
     * @summary 상품 카테고리 삭제
     * @request DELETE:/vod/api/vod/product/category/{vodProductCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodProductCategory: (
      vodProductCategoryIdx: number,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/category/${vodProductCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateVodProductSubCategory
     * @summary 상품 서브 카테고리 생성
     * @request POST:/vod/api/vod/product/subCategory
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodProductSubCategory: (
      data: CreateVodProductSubCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subCategory`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateVodProductSubCategory
     * @summary 상품 서브 카테고리 수정
     * @request PUT:/vod/api/vod/product/subCategory/{vodProductSubCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodProductSubCategory: (
      vodProductSubCategoryIdx: number,
      data: CreateVodProductSubCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subCategory/${vodProductSubCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteVodProductSubCategory
     * @summary 상품 서브 카테고리 삭제
     * @request DELETE:/vod/api/vod/product/subCategory/{vodProductSubCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodProductSubCategory: (
      vodProductSubCategoryIdx: number,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subCategory/${vodProductSubCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateVodProductSubSubCategory
     * @summary 상품 서브서브 카테고리 생성
     * @request POST:/vod/api/vod/product/subSubCategory
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodProductSubSubCategory: (
      data: CreateVodProductSubSubCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subSubCategory`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateVodProductSubSubCategory
     * @summary 상품 서브서브 카테고리 수정
     * @request PUT:/vod/api/vod/product/subSubCategory/{vodProductSubSubCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodProductSubSubCategory: (
      vodProductSubSubCategoryIdx: number,
      data: CreateVodProductSubSubCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subSubCategory/${vodProductSubSubCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteVodProductSubSubCategory
     * @summary 상품 서브서브 카테고리 삭제
     * @request DELETE:/vod/api/vod/product/subSubCategory/{vodProductSubSubCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodProductSubSubCategory: (
      vodProductSubSubCategoryIdx: number,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subSubCategory/${vodProductSubSubCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateVodProductSubSubSubCategory
     * @summary 상품 서브서브서브 카테고리 생성
     * @request POST:/vod/api/vod/product/subSubSubCategory
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodProductSubSubSubCategory: (
      data: CreateVodProductSubSubSubCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subSubSubCategory`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateVodProductSubSubSubCategory
     * @summary 상품 서브서브서브 카테고리 수정
     * @request PUT:/vod/api/vod/product/subSubSubCategory/{vodProductSubSubSubCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodProductSubSubSubCategory: (
      vodProductSubSubSubCategoryIdx: number,
      data: CreateVodProductSubSubSubCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subSubSubCategory/${vodProductSubSubSubCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteVodProductSubSubSubCategory
     * @summary 상품 서브서브서브 카테고리 삭제
     * @request DELETE:/vod/api/vod/product/subSubSubCategory/{vodProductSubSubSubCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodProductSubSubSubCategory: (
      vodProductSubSubSubCategoryIdx: number,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subSubSubCategory/${vodProductSubSubSubCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateVodProductSubSubSubSubCategory
     * @summary 상품 서브서브서브서브 카테고리 생성
     * @request POST:/vod/api/vod/product/subSubSubSubCategory
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodProductSubSubSubSubCategory: (
      data: CreateVodProductSubSubSubSubCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subSubSubSubCategory`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateVodProductSubSubSubSubCategory
     * @summary 상품 서브서브서브서브 카테고리 수정
     * @request PUT:/vod/api/vod/product/subSubSubSubCategory/{vodProductSubSubSubSubCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodProductSubSubSubSubCategory: (
      vodProductSubSubSubSubCategoryIdx: number,
      data: CreateVodProductSubSubSubSubCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subSubSubSubCategory/${vodProductSubSubSubSubCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteVodProductSubSubSubSubCategory
     * @summary 상품 서브서브서브서브 카테고리 삭제
     * @request DELETE:/vod/api/vod/product/subSubSubSubCategory/{vodProductSubSubSubSubCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodProductSubSubSubSubCategory: (
      vodProductSubSubSubSubCategoryIdx: number,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/subSubSubSubCategory/${vodProductSubSubSubSubCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateVodProduct
     * @summary *상품생성* - 챕터 - 콘텐츠
     * @request POST:/vod/api/vod/product
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodProduct: (data: CreateVodProductDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadVodProducts
     * @summary 상품들 조회
     * @request GET:/vod/api/vod/product
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodProducts: (
      query: VodControllerReadVodProductsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateVodProductStatus
     * @summary *상품상태일괄수정* - 챕터 - 콘텐츠
     * @request PUT:/vod/api/vod/product
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodProductStatus: (
      data: UpdateVodProductStatusDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteVodProduct
     * @summary *상품삭제* - 챕터 - 콘텐츠
     * @request DELETE:/vod/api/vod/product
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodProduct: (data: DeleteVodProductDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadVodProduct
     * @summary 상품 조회
     * @request GET:/vod/api/vod/product/{vodProductIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodProduct: (vodProductIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/${vodProductIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateVodProduct
     * @summary *상품수정* - 챕터 - 콘텐츠
     * @request PUT:/vod/api/vod/product/{vodProductIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodProduct: (
      vodProductIdx: number,
      data: UpdateVodProductDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/${vodProductIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadVodProductSummary
     * @summary 상품 조회(요약정보)
     * @request GET:/vod/api/vod/productSummary
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodProductSummary: (
      query: VodControllerReadVodProductSummaryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/productSummary`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateVodProductChapter
     * @summary 상품 - *챕터생성* - 콘텐츠
     * @request POST:/vod/api/vod/chapter
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodProductChapter: (
      data: CreateVodProductChapterDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/chapter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteVodProductChapter
     * @summary 상품 - *챕터삭제* - 콘텐츠
     * @request DELETE:/vod/api/vod/chapter
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodProductChapter: (
      data: DeleteVodProductChapterDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/chapter`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateVodProductChapter
     * @summary 상품 - *챕터수정* - 콘텐츠
     * @request PUT:/vod/api/vod/chapter/{vodProductChapterIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodProductChapter: (
      vodProductChapterIdx: number,
      data: UpdateVodProductChapterDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/chapter/${vodProductChapterIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateVodProductContent
     * @summary 상품 - 챕터 - *콘텐츠생성*
     * @request POST:/vod/api/vod/content
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodProductContent: (
      data: CreateVodProductContentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/content`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteVodProductContent
     * @summary 상품 - 챕터 - *콘텐츠삭제*
     * @request DELETE:/vod/api/vod/content
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodProductContent: (
      data: DeleteVodProductContentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/content`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateVodProductContent
     * @summary 상품 - 챕터 - *콘텐츠수정*
     * @request PUT:/vod/api/vod/content/{vodProductContentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodProductContent: (
      vodProductContentIdx: number,
      data: UpdateVodProductContentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/content/${vodProductContentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCheckLive
     * @summary LIVE 강사/수업 확인
     * @request POST:/vod/api/vod/check/live
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCheckLive: (data: CheckLiveDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/check/live`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreatelesson
     * @summary 레슨 생성
     * @request POST:/vod/api/vod/lesson
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreatelesson: (data: CreatelessonDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadlessons
     * @summary 레슨들
     * @request GET:/vod/api/vod/lesson
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadlessons: (query: VodControllerReadlessonsParams, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadlesson
     * @summary 레슨
     * @request GET:/vod/api/vod/lesson/{lessonIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadlesson: (lessonIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/${lessonIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerAddMember
     * @summary 레슨에 멤버 추가
     * @request POST:/vod/api/vod/lessonMember
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerAddMember: (data: AddMemberDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lessonMember`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateLessonMember
     * @summary 레슨 멤버 종강/수강취소(오늘 이후 수업이 삭제된다)
     * @request PUT:/vod/api/vod/lessonMember
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateLessonMember: (data: UpdateLessonMemberDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lessonMember`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCheckChangeSchedule
     * @summary 수업일 변경 요청전 체크(1:1만 가능)
     * @request POST:/vod/api/vod/checkChangeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCheckChangeSchedule: (data: CheckChangeScheduleDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/checkChangeSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerChangeSchedule
     * @summary 수업일 변경 요청(1:1만 가능)
     * @request POST:/vod/api/vod/changeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerChangeSchedule: (data: ChangeScheduleDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/changeSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadChangeSchedules
     * @summary 수업일 변경 요청 내역
     * @request GET:/vod/api/vod/changeSchedules
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadChangeSchedules: (
      query: VodControllerReadChangeSchedulesParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/changeSchedules`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerConfirmChangeSchedule
     * @summary 수업일 변경 승인/거부
     * @request PUT:/vod/api/vod/changeSchedule/{scheduleChangeIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerConfirmChangeSchedule: (
      scheduleChangeIdx: number,
      data: ConfirmChangeScheduleDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/changeSchedule/${scheduleChangeIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerAddSchedule
     * @summary 스케쥴 추가
     * @request POST:/vod/api/vod/addSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerAddSchedule: (data: AddScheduleDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/addSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerUpdateSchedule
     * @summary 스케쥴 변경
     * @request POST:/vod/api/vod/updateSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateSchedule: (data: UpdateScheduleDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/updateSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerDeleteSchedule
     * @summary 스케쥴 삭제
     * @request PUT:/vod/api/vod/deleteSchedule/{scheduleIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteSchedule: (scheduleIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/deleteSchedule/${scheduleIdx}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerReadSchedule
     * @summary 예정된 라이브 스케쥴 조회(리스트&캘린더&출석부)
     * @request GET:/vod/api/vod/schedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadSchedule: (
      query: VodControllerReadScheduleParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/schedule`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerCreateLarahv
     * @summary 라이브 수업 생성
     * @request POST:/vod/api/vod/schedule/live
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateLarahv: (data: CreateLarahvDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/schedule/live`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerGetLarahvRecord
     * @summary 라이브 수업 녹화 조회
     * @request POST:/vod/api/vod/schedule/live/record
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetLarahvRecord: (data: GetLarahvRecordDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/schedule/live/record`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vod
     * @name VodControllerEnterMessage
     * @summary 수업입장요청
     * @request PUT:/vod/api/vod/schedule/enterMessage/{scheduleIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerEnterMessage: (scheduleIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/schedule/enterMessage/${scheduleIdx}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name TagControllerReadTag
     * @summary 태그 검색
     * @request GET:/vod/api/tag
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    tagControllerReadTag: (query: TagControllerReadTagParams, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/tag`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name TagControllerConnetionTag
     * @summary 태그 연결해제 후 재연결
     * @request POST:/vod/api/tag/connection
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    tagControllerConnetionTag: (data: ConnetionTagDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/tag/connection`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupon
     * @name CouponControllerCreateCoupon
     * @summary 쿠폰생성
     * @request POST:/vod/api/coupon/coupon
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerCreateCoupon: (data: CreateCouponDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/coupon`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupon
     * @name CouponControllerUpdateCoupon
     * @summary 쿠폰 한개 할당/철회(철회는 memberIdx: null)
     * @request PUT:/vod/api/coupon/coupon/{couponIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerUpdateCoupon: (
      couponIdx: string,
      data: UpdateCouponDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/coupon/${couponIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupon
     * @name CouponControllerDeleteCoupon
     * @summary 쿠폰 한개 삭제
     * @request DELETE:/vod/api/coupon/one/{couponIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerDeleteCoupon: (couponIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/one/${couponIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupon
     * @name CouponControllerReadCouponGroups
     * @summary 쿠폰 그룹 조회
     * @request GET:/vod/api/coupon/group
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerReadCouponGroups: (
      query: CouponControllerReadCouponGroupsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/group`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupon
     * @name CouponControllerReadCouponGroup
     * @summary 쿠폰 그룹 한개 조회
     * @request GET:/vod/api/coupon/group/{couponGroupCode}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerReadCouponGroup: (
      { couponGroupCode, ...query }: CouponControllerReadCouponGroupParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/group/${couponGroupCode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupon
     * @name CouponControllerAddCoupon
     * @summary 쿠폰 그룹에 쿠폰 수량 추가
     * @request PUT:/vod/api/coupon/group/{couponGroupCode}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerAddCoupon: (
      couponGroupCode: string,
      data: AddCouponDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/group/${couponGroupCode}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coupon
     * @name CouponControllerDeleteCouponGroup
     * @summary 쿠폰 그룹 삭제
     * @request DELETE:/vod/api/coupon/group/{couponGroupCode}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerDeleteCouponGroup: (couponGroupCode: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/group/${couponGroupCode}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerReadPayments
     * @summary 결제 결과 조회
     * @request GET:/vod/api/payment
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerReadPayments: (
      query: PaymentControllerReadPaymentsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/payment`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerPaymentRefund
     * @summary 환불
     * @request PUT:/vod/api/payment/refund/{paymentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerPaymentRefund: (
      paymentIdx: string,
      data: PaymentRefundDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/payment/refund/${paymentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LiveTicket
     * @name LiveTicketControllerAddLiveTicket
     * @summary 티켓추가
     * @request POST:/vod/api/liveTicket
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    liveTicketControllerAddLiveTicket: (data: AddLiveTicketDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/liveTicket`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LiveTicket
     * @name LiveTicketControllerDeleteLiveTicket
     * @summary 티켓삭제
     * @request DELETE:/vod/api/liveTicket/{liveTicketIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    liveTicketControllerDeleteLiveTicket: (liveTicketIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/liveTicket/${liveTicketIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerCategoryFileUpload
     * @summary 디자인 연관파일 업로드
     * @request POST:/vod/api/template/photo/upload
     * @secure
     * @response `201` `TemplateFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerCategoryFileUpload: (
      data: TemplateFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<TemplateFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/template/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerReadPost
     * @summary 메뉴조회
     * @request GET:/vod/api/template/menu
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerReadPost: (
      query: TemplateControllerReadPostParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template/menu`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerCreateMenu
     * @summary 메뉴생성
     * @request POST:/vod/api/template/menu
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerCreateMenu: (data: SaveMenuDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template/menu`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerUpdateMenu
     * @summary 메뉴수정
     * @request PUT:/vod/api/template/menu/{menuIdx}
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerUpdateMenu: (
      menuIdx: string,
      data: SaveMenuDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template/menu/${menuIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerDeleteMenu
     * @summary 메뉴삭제
     * @request DELETE:/vod/api/template/menu/{menuIdx}
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerDeleteMenu: (menuIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template/menu/${menuIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerCreateSubMenu
     * @summary 서브메뉴생성
     * @request POST:/vod/api/template/subMenu
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerCreateSubMenu: (data: SaveSubMenuDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template/subMenu`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerUpdateSubMenu
     * @summary 서브메뉴수정
     * @request PUT:/vod/api/template/subMenu/{subMenuIdx}
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerUpdateSubMenu: (
      subMenuIdx: string,
      data: SaveSubMenuDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template/subMenu/${subMenuIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerDeleteSubMenu
     * @summary 서브메뉴삭제
     * @request DELETE:/vod/api/template/subMenu/{subMenuIdx}
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerDeleteSubMenu: (subMenuIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template/subMenu/${subMenuIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerAdminSignin
     * @summary 템플릿가져오기
     * @request GET:/vod/api/template
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerAdminSignin: (
      query: TemplateControllerAdminSigninParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerCreateTemplate
     * @summary 템플릿 생성
     * @request POST:/vod/api/template
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerCreateTemplate: (data: SaveTemplateDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerUpdateTemplate
     * @summary 템플릿 수정
     * @request PUT:/vod/api/template/{templateIdx}
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerUpdateTemplate: (
      templateIdx: number,
      data: SaveTemplateDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template/${templateIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name TemplateControllerDeleteTemplate
     * @summary 서브메뉴삭제
     * @request DELETE:/vod/api/template/{templateIdx}
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    templateControllerDeleteTemplate: (templateIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/template/${templateIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerQuizPhotoFileUpload
     * @summary 사진 업로드
     * @request POST:/vod/api/quiz/photo/upload
     * @secure
     * @response `201` `QuizFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerQuizPhotoFileUpload: (data: QuizPhotoFileUploadDto, params: RequestParams = {}) =>
      this.request<QuizFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerCreateQuestion
     * @summary Question(문제) 입력
     * @request POST:/vod/api/quiz/question
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerCreateQuestion: (data: CreateQuestionDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz/question`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerReadQuestion
     * @summary Question(문제) 조회
     * @request GET:/vod/api/quiz/question
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerReadQuestion: (
      query: QuizControllerReadQuestionParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz/question`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerDeleteQuestion
     * @summary Question(문제) 삭제
     * @request DELETE:/vod/api/quiz/question
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerDeleteQuestion: (data: DeleteQuestionDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz/question`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerUpdateQuestion
     * @summary Question(문제) 수정
     * @request PUT:/vod/api/quiz/question/{questionIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerUpdateQuestion: (
      questionIdx: number,
      data: UpdateQuestionDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz/question/${questionIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerConnetionQuiz
     * @summary Quiz-Question 연결해제 후 재연결
     * @request PUT:/vod/api/quiz/connection
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerConnetionQuiz: (data: ConnetionQuizDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz/connection`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerDisconnetionQuiz
     * @summary Quiz-Question 연결해제
     * @request DELETE:/vod/api/quiz/disconnection/{questionGroupIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerDisconnetionQuiz: (questionGroupIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz/disconnection/${questionGroupIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerCreateQuiz
     * @summary Quiz(시험지) 생성
     * @request POST:/vod/api/quiz
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerCreateQuiz: (data: CreateQuizDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerReadQuiz
     * @summary Quiz(시험지)들 조회
     * @request GET:/vod/api/quiz
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerReadQuiz: (query: QuizControllerReadQuizParams, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerDeleteQuiz
     * @summary Quiz 삭제
     * @request DELETE:/vod/api/quiz
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerDeleteQuiz: (data: DeleteQuizDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerUpdateQuiz
     * @summary Quiz(시험지) 수정
     * @request PUT:/vod/api/quiz/{quizIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerUpdateQuiz: (quizIdx: number, data: UpdateQuizDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz/${quizIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerReadQuiz2
     * @summary Quiz(시험지) 조회
     * @request GET:/vod/api/quiz/{quizIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerReadQuiz2: (quizIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz/${quizIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quiz
     * @name QuizControllerReadQuizHistory
     * @summary 제출된 Quiz(시험지) 조회
     * @request POST:/vod/api/quiz/history
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    quizControllerReadQuizHistory: (
      query: QuizControllerReadQuizHistoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/quiz/history`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Member
     * @name WpMemberControllerWpPhotoFileUpload
     * @summary 사진 업로드
     * @request POST:/vod/api/wp/member/photo/upload
     * @secure
     * @response `201` `WpFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpMemberControllerWpPhotoFileUpload: (data: WpPhotoFileUploadDto, params: RequestParams = {}) =>
      this.request<WpFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/member/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Member
     * @name WpMemberControllerWpReadMyInfo
     * @summary 내정보
     * @request GET:/vod/api/wp/member/myinfo
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpMemberControllerWpReadMyInfo: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/member/myinfo`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Member
     * @name WpMemberControllerWpUpdateMyInfo
     * @summary 내정보 수정
     * @request PUT:/vod/api/wp/member/myinfo
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpMemberControllerWpUpdateMyInfo: (data: WpUpdateMyInfoDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/member/myinfo`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Member
     * @name WpMemberControllerWpAddKakao
     * @summary 카카오로그인 추가
     * @request POST:/vod/api/wp/member/social/kakao
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpMemberControllerWpAddKakao: (data: WpAddKakaoDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/member/social/kakao`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Member
     * @name WpMemberControllerWpAddNaver
     * @summary 네이버로그인 추가
     * @request POST:/vod/api/wp/member/social/naver
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpMemberControllerWpAddNaver: (data: WpAddNaverDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/member/social/naver`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Member
     * @name WpMemberControllerWpChangePassword
     * @summary 비밀번호 변경
     * @request POST:/vod/api/wp/member/password
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpMemberControllerWpChangePassword: (data: WpChangePasswordDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/member/password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Member
     * @name WpMemberControllerWpDelete
     * @summary 탈퇴
     * @request POST:/vod/api/wp/member/delete
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpMemberControllerWpDelete: (data: WpDeleteDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/member/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Member
     * @name WpMemberControllerWpAddCoupon
     * @summary 쿠폰등록
     * @request POST:/vod/api/wp/member/coupon
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpMemberControllerWpAddCoupon: (data: WpAddCouponDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/member/coupon`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Member
     * @name WpMemberControllerWpReadCoupon
     * @summary 쿠폰조회
     * @request GET:/vod/api/wp/member/coupon
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpMemberControllerWpReadCoupon: (
      query: WpMemberControllerWpReadCouponParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/member/coupon`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Member
     * @name WpMemberControllerWpReadEvent
     * @summary 이벤트 조회
     * @request GET:/vod/api/wp/member/event
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpMemberControllerWpReadEvent: (
      query: WpMemberControllerWpReadEventParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/member/event`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadlessons
     * @summary 내 레슨들
     * @request GET:/vod/api/wp/vod/lesson
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadlessons: (
      query: WpVodControllerReadlessonsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/lesson`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadlesson
     * @summary 내 레슨
     * @request GET:/vod/api/wp/vod/lesson/{lessonIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadlesson: (
      { lessonIdx, ...query }: WpVodControllerReadlessonParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/lesson/${lessonIdx}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerGetUrl
     * @summary 내 레슨-URL획득
     * @request GET:/vod/api/wp/vod/url
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetUrl: (query: WpVodControllerGetUrlParams, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/url`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerGetUrl2
     * @summary 내 레슨-URL획득(주의)
     * @request GET:/vod/api/wp/vod/url2
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetUrl2: (query: WpVodControllerGetUrl2Params, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/url2`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadLarahvRecord
     * @summary 라이브 수업 녹화 조회
     * @request POST:/vod/api/wp/vod/live/record
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadLarahvRecord: (data: ReadLarahvRecordDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/live/record`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadVodProductCategory
     * @summary 상품 카테고리 조회
     * @request GET:/vod/api/wp/vod/product/category
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadVodProductCategory: (
      query: WpVodControllerReadVodProductCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/product/category`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadWpVodProducts
     * @summary 상품들 조회
     * @request GET:/vod/api/wp/vod/product
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadWpVodProducts: (
      query: WpVodControllerReadWpVodProductsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/product`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadWpVodProduct
     * @summary 상품 조회
     * @request GET:/vod/api/wp/vod/product/{vodProductIdx}
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadWpVodProduct: (
      { vodProductIdx, ...query }: WpVodControllerReadWpVodProductParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/product/${vodProductIdx}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadVodProductSummary
     * @summary 상품 조회(요약정보)
     * @request GET:/vod/api/wp/vod/productSummary
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadVodProductSummary: (
      query: WpVodControllerReadVodProductSummaryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/productSummary`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadAdmins
     * @summary 강사 조회
     * @request GET:/vod/api/wp/vod/admin
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadAdmins: (
      query: WpVodControllerReadAdminsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/admin`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerCreateWishlist
     * @summary 찜하기
     * @request POST:/vod/api/wp/vod/wishlist
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCreateWishlist: (data: CreateWishlistDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/wishlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerGetWishlist
     * @summary 찜목록 조회
     * @request GET:/vod/api/wp/vod/wishlist
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetWishlist: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/wishlist`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerDeleteWishlist
     * @summary 찜삭제
     * @request DELETE:/vod/api/wp/vod/wishlist/{vodProductIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerDeleteWishlist: (vodProductIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/wishlist/${vodProductIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerCheckChangeSchedules
     * @summary 남은 수업 LIVE 강사/수업 변경전 체크(1:1만 가능)
     * @request POST:/vod/api/wp/vod/checkChangeSchedules
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCheckChangeSchedules: (
      data: CheckChangeSchedulesDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/checkChangeSchedules`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerCheckChangeSchedules2
     * @summary 남은 수업 LIVE 강사/수업 변경전 체크2(1:1만 가능)
     * @request POST:/vod/api/wp/vod/checkChangeSchedules2
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCheckChangeSchedules2: (
      data: CheckChangeSchedules2Dto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/checkChangeSchedules2`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerChangeSchedules
     * @summary 남은 수업 LIVE 강사/수업 변경(1:1만 가능)
     * @request POST:/vod/api/wp/vod/changeSchedules
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerChangeSchedules: (data: WpChangeSchedulesDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/changeSchedules`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadChangeSchedules
     * @summary 수업일 변경 요청 내역
     * @request GET:/vod/api/wp/vod/changeSchedules
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadChangeSchedules: (
      query: WpVodControllerReadChangeSchedulesParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/changeSchedules`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerCheckChangeSchedule
     * @summary 수업일 변경 요청전 체크(1:1만 가능)
     * @request POST:/vod/api/wp/vod/checkChangeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCheckChangeSchedule: (
      data: WpCheckChangeScheduleDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/checkChangeSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerCheckChangeSchedule2
     * @summary 수업일 변경 요청전 체크2(1:1만 가능)
     * @request POST:/vod/api/wp/vod/checkChangeSchedule2
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCheckChangeSchedule2: (
      data: WpCheckChangeSchedule2Dto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/checkChangeSchedule2`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerChangeSchedule
     * @summary 수업일 변경 요청(1:1만 가능)
     * @request POST:/vod/api/wp/vod/changeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerChangeSchedule: (data: WpChangeScheduleDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/changeSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerConfirmChangeSchedule
     * @summary 수업일 변경 승인/거부
     * @request PUT:/vod/api/wp/vod/changeSchedule/{scheduleChangeIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerConfirmChangeSchedule: (
      scheduleChangeIdx: number,
      data: ConfirmChangeScheduleDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/changeSchedule/${scheduleChangeIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerCheckTicket
     * @summary 티켓 사용전 체크
     * @request POST:/vod/api/wp/vod/checkTicket
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCheckTicket: (data: WpCheckTicketDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/checkTicket`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerCheckTicket2
     * @summary 티켓 사용전 체크2
     * @request POST:/vod/api/wp/vod/checkTicket2
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCheckTicket2: (data: WpCheckTicket2Dto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/checkTicket2`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerUseTicket
     * @summary 티켓 사용
     * @request POST:/vod/api/wp/vod/useTicket
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerUseTicket: (data: WpCheckTicketDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/useTicket`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerCreateAdminAssess
     * @summary 강사 평가 생성
     * @request POST:/vod/api/wp/vod/assess/admin
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCreateAdminAssess: (data: WpCreateAdminAssessDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/assess/admin`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadAdminAssess
     * @summary 강사 평가 목록(로그인X)
     * @request GET:/vod/api/wp/vod/assess/admin
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadAdminAssess: (
      query: WpVodControllerReadAdminAssessParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/assess/admin`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerReadAdminAssess2
     * @summary 강사 평가 상세(내것만조회가능
     * @request GET:/vod/api/wp/vod/assess/admin/{adminAssessIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadAdminAssess2: (adminAssessIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/assess/admin/${adminAssessIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerUpdateAdminAssess
     * @summary 강사 평가 수정
     * @request PUT:/vod/api/wp/vod/assess/admin/{adminAssessIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerUpdateAdminAssess: (
      adminAssessIdx: number,
      data: WpUpdateAdminAssessDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/assess/admin/${adminAssessIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Vod
     * @name WpVodControllerWpReadReference
     * @summary 카테고리로 파일 검색
     * @request POST:/vod/api/wp/vod/reference
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerWpReadReference: (data: WpReadReferenceDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/reference`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Payment
     * @name WpPaymentControllerCheckPayment
     * @summary 금액확인
     * @request POST:/vod/api/wp/payment/check
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerCheckPayment: (data: WpCheckPaymentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/check`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Payment
     * @name WpPaymentControllerCheckLive
     * @summary LIVE 강사/수업 확인
     * @request POST:/vod/api/wp/payment/check/live
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerCheckLive: (data: WpCheckLiveDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/check/live`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Payment
     * @name WpPaymentControllerCheckLive2
     * @summary LIVE 강사/수업 확인2
     * @request POST:/vod/api/wp/payment/check/live2
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerCheckLive2: (data: WpCheckLive2Dto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/check/live2`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Payment
     * @name WpPaymentControllerBeforePayment
     * @summary 결제전
     * @request POST:/vod/api/wp/payment/before
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerBeforePayment: (data: WpBeforePaymentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/before`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Payment
     * @name WpPaymentControllerCancelPayment
     * @summary 결제취소시
     * @request POST:/vod/api/wp/payment/cancel
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerCancelPayment: (data: WpCancelPaymentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/cancel`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Payment
     * @name WpPaymentControllerAfterPayment
     * @summary 결제후
     * @request PUT:/vod/api/wp/payment/payment
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerAfterPayment: (data: WpAfterPaymentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/payment`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Payment
     * @name WpPaymentControllerAfterZeroPayment
     * @summary 0원 결제후
     * @request PUT:/vod/api/wp/payment/payment/{PCD_PAY_OID}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerAfterZeroPayment: (pcdPayOid: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/payment/${pcdPayOid}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Payment
     * @name WpPaymentControllerReadPayment
     * @summary 결제내역
     * @request GET:/vod/api/wp/payment
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerReadPayment: (
      query: WpPaymentControllerReadPaymentParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Payment
     * @name WpPaymentControllerRefund
     * @summary 취소요청
     * @request POST:/vod/api/wp/payment/refund
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerRefund: (data: WpRefundDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/refund`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-PaymentGlobal
     * @name WpPaymentGlobalControllerAfterGlobalPayment
     * @summary 글로벌 결제후
     * @request POST:/vod/api/wp/paymentGlobal/payment
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentGlobalControllerAfterGlobalPayment: (
      data: WpAfterGlobalPaymentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/paymentGlobal/payment`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerBoardPhotoFileUpload
     * @summary 게시글 사진 업로드(로그인X)
     * @request POST:/vod/api/wp/board/photo/upload
     * @response `201` `WpBoardFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerBoardPhotoFileUpload: (
      data: WpBoardFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<WpBoardFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/photo/upload`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerReadWpBoardClass
     * @summary 게시판 조회(로그인X)
     * @request GET:/vod/api/wp/board/class
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpBoardClass: (
      query: WpBoardControllerReadWpBoardClassParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/class`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerReadWpBoardCategory
     * @summary 게시판 카테고리 조회(로그인X)
     * @request GET:/vod/api/wp/board/categeory
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpBoardCategory: (
      query: WpBoardControllerReadWpBoardCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/categeory`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerReadWpPosts
     * @summary 게시글 조회(로그인X)
     * @request GET:/vod/api/wp/board/post
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpPosts: (
      query: WpBoardControllerReadWpPostsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/post`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerReadWpMyPosts
     * @summary 게시글들 조회(Content 노출형)
     * @request GET:/vod/api/wp/board/signin/post
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpMyPosts: (
      query: WpBoardControllerReadWpMyPostsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/post`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerCreateWpPost
     * @summary 게시글 생성
     * @request POST:/vod/api/wp/board/signin/post
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerCreateWpPost: (data: CreateWpPostDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/post`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerReadWpMyPosts2
     * @summary 게시글들 조회(Content 미노출형)
     * @request GET:/vod/api/wp/board/signin/post2
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpMyPosts2: (
      query: WpBoardControllerReadWpMyPosts2Params,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/post2`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerReadWpMyPost
     * @summary 게시글 조회
     * @request GET:/vod/api/wp/board/signin/post/{postIdx}
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpMyPost: (
      { postIdx, ...query }: WpBoardControllerReadWpMyPostParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/post/${postIdx}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerUpdateWpPost
     * @summary 게시글 수정
     * @request PUT:/vod/api/wp/board/signin/post/{postIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerUpdateWpPost: (
      postIdx: string,
      data: UpdateWpPostDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/post/${postIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerDeleteWpPost
     * @summary 게시글 삭제
     * @request DELETE:/vod/api/wp/board/signin/post/{postIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerDeleteWpPost: (postIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/post/${postIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerCreateWpComment
     * @summary 댓글 생성
     * @request POST:/vod/api/wp/board/comment
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerCreateWpComment: (data: CreateWpCommentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/comment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerUpdateWpComment
     * @summary 댓글 수정
     * @request PUT:/vod/api/wp/board/commnet/{commentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerUpdateWpComment: (
      commentIdx: string,
      data: UpdateWpCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/commnet/${commentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerDeleteWpComment
     * @summary 댓글 삭제
     * @request DELETE:/vod/api/wp/board/commnet/{commentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerDeleteWpComment: (commentIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/commnet/${commentIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerCreateWpCocomment
     * @summary 대댓글 생성
     * @request POST:/vod/api/wp/board/cocomment
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerCreateWpCocomment: (data: CreateWpCocommentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/cocomment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerUpdateWpCocomment
     * @summary 대댓글 수정
     * @request PUT:/vod/api/wp/board/cocommnet/{cocommentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerUpdateWpCocomment: (
      cocommentIdx: string,
      data: UpdateWpCocommentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/cocommnet/${cocommentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Board
     * @name WpBoardControllerDeleteWpCocomment
     * @summary 대댓글 삭제
     * @request DELETE:/vod/api/wp/board/cocommnet/{cocommentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerDeleteWpCocomment: (cocommentIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/cocommnet/${cocommentIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Quiz
     * @name WpQuizControllerReadWpQuestion
     * @summary Question(문제) 조회
     * @request GET:/vod/api/wp/quiz/question/{questionIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuizControllerReadWpQuestion: (questionIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/quiz/question/${questionIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Quiz
     * @name WpQuizControllerReadWpQuiz
     * @summary Quiz(시험지) 조회
     * @request GET:/vod/api/wp/quiz
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuizControllerReadWpQuiz: (
      query: WpQuizControllerReadWpQuizParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/quiz`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Quiz
     * @name WpQuizControllerCreateAnswer
     * @summary Quiz(시험지) 제출
     * @request POST:/vod/api/wp/quiz
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuizControllerCreateAnswer: (data: CreateAnswerDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/quiz`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Quiz
     * @name WpQuizControllerPlatomLevelTest
     * @summary 플라톰(레벨테스트) Quiz(시험지) 조회
     * @request GET:/vod/api/wp/quiz/leveltest
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuizControllerPlatomLevelTest: (
      query: WpQuizControllerPlatomLevelTestParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/quiz/leveltest`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Quiz
     * @name WpQuizControllerCreateLevelTestAnswer
     * @summary 플라톰(레벨테스트)Quiz(시험지) 제출
     * @request POST:/vod/api/wp/quiz/leveltest
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuizControllerCreateLevelTestAnswer: (
      data: CreateLevelTestAnswerDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/quiz/leveltest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Quiz
     * @name WpQuizControllerReadWpQuizHistory
     * @summary 제출된 Quiz(시험지) 조회
     * @request GET:/vod/api/wp/quiz/history
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuizControllerReadWpQuizHistory: (
      query: WpQuizControllerReadWpQuizHistoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/quiz/history`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Quiz
     * @name WpQuizControllerUpdateWpQuizHistory
     * @summary 제출된 시험지 수정
     * @request PUT:/vod/api/wp/quiz/history/{quizHistoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuizControllerUpdateWpQuizHistory: (
      { quizHistoryIdx, ...query }: WpQuizControllerUpdateWpQuizHistoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/quiz/history/${quizHistoryIdx}`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Quiz
     * @name WpQuizControllerGenerateDashboardData
     * @summary 시험지 분석
     * @request POST:/vod/api/wp/quiz/analysis
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuizControllerGenerateDashboardData: (
      data: AnalysisWpQuizHistoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/quiz/analysis`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 회원용-Quiz
     * @name WpQuizControllerGenerateRecentQuizStats
     * @summary 최근 시험지 분석
     * @request POST:/vod/api/wp/quiz/analysis5
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuizControllerGenerateRecentQuizStats: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/quiz/analysis5`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
